<template>
    <Portal to="modal">
        <ModalContainer key="registerModal">
            <template #head>
                <RegisterHead
                    :is-free-exam="selectedExam && selectedExam.isFree"
                    :current-step="currentStep"
                    @back="goBack"
                />
            </template>
            <div 
                class="register"
                :class="{
                    'register--choose-plan': currentStep === 3
                }"
            >
                <template v-if="!isLoading">
                    <Banner
                        v-if="validReferral"
                        class="register__banner"
                    >
                        <template #bannerText>
                            <template v-if="'name' in validReferral && validReferral.name">
                                {{ validReferral.name }}
                            </template>
                            <template v-else-if="'objectId' in validReferral">
                                Someone sent you 20% off your first subscription with Pocket Prep. Sweet!
                            </template>
                        </template>
                    </Banner>
                    <Errors
                        v-else-if="!validReferral && referralError && currentStep === 1"
                        :errors="[ referralError ]"
                        class="register__error-banner"
                    />
                    <div
                        v-if="currentStep > 0"
                        class="register__step"
                        :class="{
                            'register__step--referral': validReferral
                        }"
                        aria-live="polite"
                    >
                        Step {{ currentStep }} of 3
                    </div>
                    <h1
                        class="register__title"
                        tabindex="-1"
                        role="heading"
                    >
                        <template v-if="currentStep === 1 && !selectedExam">
                            Let's prep! What exam are you studying for?
                        </template>
                        <template v-else-if="currentStep === 1 && !!selectedExam && examVersions.length <= 1">
                            Excellent, let's study for the {{ selectedExam.nativeAppName }}.
                        </template>
                        <template v-else-if="currentStep === 1 && !!selectedExam">
                            Choose Exam Version
                        </template>
                        <template v-else-if="currentStep === 2">
                            Account Details
                        </template>
                        <template v-else-if="currentStep === 3 && !showPaymentForm">
                            Choose Your Plan
                        </template>
                        <template v-else-if="currentStep === 3 && showPaymentForm">
                            Payment Method
                        </template>
                    </h1>
                    <Onboarding
                        v-if="currentStep === 0"
                        :valid-referral="validReferral"
                        @next="moveToChooseExam"
                    />
                    <ChooseExam
                        v-if="currentStep === 1 && !selectedExam"
                        class="register__choose-exam"
                        @select="selectExam"
                        @selectBundle="selectBundle"
                        @search="search"
                    />
                    <SelectedExam
                        v-if="currentStep === 1 && !!selectedExam && examVersions.length <= 1"
                        :exam="selectedExam"
                        :bundle="selectedExam.bundle"
                        :referral="validReferral"
                        @next="confirmExam"
                        @cancel="backToExamSearch"
                    />
                    <SelectVersion
                        v-if="currentStep === 1 && !!selectedExam && examVersions.length > 1"
                        :exam="selectedExam"
                        :exam-versions="examVersions"
                        :bundle="selectedExam.bundle"
                        @next="confirmExam"
                        @cancel="backToExamSearch"
                        @selectExamVersion="selectExamVersion"
                    />
                    <AccountDetails
                        v-if="currentStep === 2"
                        :exam="selectedExam"
                        :saved-password="password"
                        :exam-versions="examVersions"
                        :valid-referral="validReferral"
                        @submit="submitAccountDetails"
                    />
                    <ChoosePlan
                        v-if="currentStep === 3 && 
                            selectedExam && 
                            !showPaymentForm"
                        :exam="selectedExam"
                        :bundle="selectedExam.bundle"
                        :saved-selected-plan-id="selectedPlanId"
                        :valid-referral="validReferral"
                        :referral-error="referralError"
                        @confirmPlan="confirmPlan"
                    />
                    <RegisterPayment
                        v-if="currentStep === 3 && showPaymentForm && selectedPlanId !== 'free' && selectedExam"
                        :saved-plan-id="selectedPlanId"
                        :bundle="selectedExam.bundle"
                        :is-loading="isLoadingPayment"
                        :payment-error="paymentError"
                        @error="paymentError = null"
                        @submitPayment="submitPayment"
                    />
                </template>
                <div 
                    class="register__financial-support" 
                    v-if=" currentStep === 3 && 
                        selectedExam && 
                        !showPaymentForm"
                >
                    <div class="register__financial-support-message">
                        Need help paying for Premium? Learn about our
                        <PocketLink
                            :href="`https://www.pocketprep.com/pocket-prep-financial-support-program?${
                                buildUTMParamString({
                                    utm_campaign: 'app_settings',
                                    utm_content: 'financial_support_program',
                                })
                            }`"
                            target="_blank"
                            class="register__financial-support-link"
                            aria-label="Financial Support Program. Open help center article in browser view."
                            @mousedown.prevent
                        >
                            Financial Support Program
                            <Icon type="externalLink" />
                        </PocketLink>
                    </div>
                </div>
            </div>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'
import type { Study } from '@pocketprep/types'
import UIKit from '@pocketprep/ui-kit'
import Onboarding from '@/components/Register/Onboarding.vue'
import ChooseExam, { type TBundleExam } from '@/components/Register/ChooseExam.vue'
import SelectedExam from '@/components/Register/SelectedExam.vue'
import SelectVersion from '@/components/Register/SelectVersion.vue'
import AccountDetails from '@/components/Register/AccountDetails.vue'
import ChoosePlan from '@/components/Register/ChoosePlan.vue'
import RegisterHead from '@/components/Register/RegisterHead.vue'
import RegisterPayment from '@/components/Register/RegisterPayment.vue'
import { stripeModule } from '@/store/stripe/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { bundleModule } from '@/store/bundle/module'
import { userModule } from '@/store/user/module'
import { referralModule } from '@/store/referral/module'
import { analyticsModule } from '@/store/analytics/module'
import { subscriptionModule } from '@/store/subscription/module'
import { buildUTMParamString } from '@/store/utils'

@Component({
    components: {
        ModalContainer: UIKit.ModalContainer,
        Onboarding,
        ChooseExam,
        RegisterHead,
        SelectedExam,
        SelectVersion,
        AccountDetails,
        ChoosePlan,
        RegisterPayment,
        PocketLink: UIKit.Link,
        Banner: UIKit.Banner,
        Errors: UIKit.Errors,
        Icon: UIKit.Icon,
    },
})
export default class Register extends Vue {
    currentStep = 0
    selectedExam: null | TBundleExam = null
    selectedBundle: null | Study.Class.BundleJSON = null
    firstName = ''
    lastName = ''
    email = ''
    password = ''
    agreed = false
    selectedPlanId: null | string = null
    showPaymentForm = false
    searchVal = ''
    isLoading = true
    isLoadingPayment = false
    paymentError: null | string = null
    version = import.meta.env.VUE_APP_VERSION

    get examVersions () {
        return ([ ...this.selectedExam?.bundle.exams.map(e =>
            examMetadataModule.getters.getExamMetadataById({ excludeDiscontinued: true })[e.objectId]
        ) || [] ])
            .filter(e => e && e.examGuid === this.selectedExam?.examGuid)
            .sort((a, b) => -a.version.localeCompare(b.version, undefined, { numeric: true }))
    }

    get validReferral () {
        return referralModule.getters.getValidReferral()
    }

    get referralError () {
        return referralModule.getters.getReferralError()
    }

    async mounted () {
        await Promise.all([
            userModule.actions.fetchUserData(),
            stripeModule.actions.fetchStripePlans(),
            referralModule.actions.validateReferral(),
        ])

        // check if the user has already registered by checking if they have seen
        if (userModule.state.user?.webConfig?.hasSeenWelcomeModal 
            || userModule.state.user?.webConfig?.hasSeenProductAnnouncementModal) {
            this.$router.push({ name: 'study', query: this.$route.query })
            return
        }

        if (typeof this.$route.query.license === 'string') {
            this.$router.push({
                name: 'invite',
                query: {
                    license: this.$route.query.license,
                    email: this.$route.query.email,
                },
            })
        } else if (typeof this.$route.query.exam === 'string') {
            this.updateRouteStep('account')
            await Promise.all([
                examMetadataModule.actions.fetchExamMetadata(),
                bundleModule.actions.fetchBundles(),
            ])

            const examMetadata = examMetadataModule.getters.getMostRecentExamForExamGuid(this.$route.query.exam)
            const bundle = bundleModule.getters.getBundles()
                .find(b => b.exams.find(e => e.objectId === examMetadata?.objectId))

            this.selectedExam = bundle
                ? {
                    ...examMetadata,
                    bundle,
                }
                : null
            this.selectedBundle = bundle || null
        } else {
            this.updateRouteStep('onboarding')
        }

        this.focusTitle()
        this.isLoading = false
    }

    buildUTMParamString = buildUTMParamString

    focusTitle () {
        setTimeout(() => {
            const titleEl = document.querySelector('.register__title') as HTMLElement
            titleEl?.focus()
        }, 1)
    }

    scrollTop () {
        const registerEl = document.querySelector('.register')
        registerEl?.scrollTo(0, 0)
    }

    search (searchVal: string) {
        this.searchVal = searchVal
    }

    moveToChooseExam () {
        this.currentStep = 1
        this.updateRouteStep('industry') 
    }

    selectBundle (bundle: Study.Class.BundleJSON) {
        this.selectedBundle = bundle
        this.updateRouteStep('exam')
        this.scrollTop()
    }

    selectExam (exam: TBundleExam) {
        this.selectedExam = exam
        this.updateRouteStep('account')
    }

    selectExamVersion (exam: Study.Class.ExamMetadataJSON) {
        if (!this.selectedExam?.bundle || !exam) {
            return
        }

        this.selectedExam = {
            ...exam,
            bundle: this.selectedExam?.bundle,
        }
    }

    updateRouteStep (stepName: string) {
        if (this.$route.query.step !== stepName) {
            this.$router.replace({
                name: 'register',
                query: {
                    ...this.$route.query,
                    step: stepName,
                },
            })
        }

        this.focusTitle()
    }

    backToExamSearch () {
        this.currentStep = 1
        this.selectedExam = null
        this.selectedBundle = null
        this.scrollTop()
    }

    async confirmExam () {
        this.currentStep = 2
        this.updateRouteStep('account_details')
        this.scrollTop()
    }

    submitAccountDetails ({ password }: { password: string }) {
        this.password = password
        this.agreed = true

        if (this.selectedExam?.isFree) {
            this.confirmPlan('free')
        } else {
            this.currentStep = 3
            this.updateRouteStep('plan')
        }

        this.scrollTop()
    }

    amplitudeUpgradeViewed (subscriptionNumOfDays?: number) {
        analyticsModule.actions.amplitudeTrack('Upgrade Viewed', {
            bundleId: this.selectedBundle?.objectId,
            client: 'Web Study',
            clientAppId: 'study.pocketprep.com',
            clientVersion: this.version,
            examGuid: this.selectedExam?.examGuid,
            userDomain: userModule.getters.getUserDomain() || undefined,
            upgradeDidSubscribe: !!subscriptionModule.getters.getSubscriptionForExamId(),
            upgradePriorScreen: 'Onboarding',
            upgradeSubscriptionDays: subscriptionNumOfDays || 0,
        })
    }

    async confirmPlan (planId: string) {
        this.selectedPlanId = planId


        if (planId === 'free') {
            this.amplitudeUpgradeViewed()
            await this.$router.push({ name: 'study' })
        } else {
            this.showPaymentForm = true
            this.updateRouteStep('payment')
            this.scrollTop()
        }
    }

    goBack () {
        if (this.currentStep === 3 && this.showPaymentForm === true) {
            this.showPaymentForm = false
        } else {
            this.currentStep = (this.currentStep || 2) - 1
        }
        this.focusTitle()
    }

    async submitPayment (params: {successfulTransaction: boolean; subscriptionNumOfDays?: number}) {
        if (this.isLoadingPayment) {
            return
        }

        this.isLoadingPayment = true

        // make sure confirm and activate payment was successful
        if (params.successfulTransaction) {
            this.amplitudeUpgradeViewed(params.subscriptionNumOfDays)
            await this.$router.push({ name: 'study' })
        } else {
            // if it was not a successful payment
            this.isLoadingPayment = false
            throw new Error('Unable to process payment. Please try again.')
        }

        this.isLoadingPayment = false
    }
}
</script>
<style lang="scss" scoped>
.register {
    width: 946px;
    background: rgba($gray-background, 0.4);
    height: calc(80vh - 56px);
    min-height: 640px;
    box-sizing: border-box;
    overflow: auto;
    border-radius: 0 0 12px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;

    &--choose-plan {
        @include breakpoint(polar-bear) {
            height: 100%
        }
    }

    @include breakpoint(brown-bear) {
        width: 740px;
        height: calc(100vh - 140px) !important;
    }

    @include breakpoint(black-bear) {
        max-height: none;
        height: calc(100% - 56px) !important;
        width: 100%;
        min-height: 0;
    }

    &__banner {
        max-width: 600px;
        height: auto;
        margin-top: 24px;

        @include breakpoint(black-bear) {
            max-width: 350px;
        }
    }

    &__error-banner {
        margin-top: 24px;
        width: 550px;

        @include breakpoint(black-bear) {
            margin-top: 16px;
            max-width: 300px;
        }
    }

    &__choose-exam {
        @include breakpoint(black-bear) {
            margin-bottom: 0;
        }
    }

    &__existing-account {
        display: none;

        @include breakpoint(brown-bear) {
            display: block;
            margin: 12px 0 5px;
            text-align: center;
        }
    }

    &__step {
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        color: $slate-03;
        padding-top: 46px;
        margin-bottom: 10px;

        &--referral {
            padding-top: 40px;
        }

        @include breakpoint (black-bear) {
            padding-top: 21px;
        }
    }

    &__title {
        font-size: 26px;
        outline: none;
        line-height: 36px;
        font-weight: 700;
        text-align: center;
        margin: 0;
        padding: 0 25px;

        @include breakpoint(black-bear) {
            max-width: 323px;
        }
    }

    &__financial-support {
        display: flex;
        width: 100%;
        align-items: center;
        align-content: center;
        padding-top: 14px;
        padding-bottom: 14px;
        color: $slate;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        border-radius: 0px 0px 12px 12px;

        @include breakpoint(black-bear) {
            height: 66px;
        }
    }

    &__financial-support-message {
        margin-left: 80px;

        @include breakpoint(black-bear) {
            margin-left: 27px;
            margin-right: 28px;
        }
    }

    &__financial-support-link {
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;

        svg {
            margin-left: 3px;
            width: 9px;
            height: 10px;
        }
    }
}
</style>
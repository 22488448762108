import { examMetadataModule } from '@/store/examMetadata/module'
import { userModule } from '@/store/user/module'
import { bundleModule } from '@/store/bundle/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { isPromise } from '@/store/utils'
import { uniqBy } from '@/utils'
import type { Study } from '@pocketprep/types'

const getExamMetadataById = (options?: { excludeDiscontinued?: boolean }) => {
    const examMetadata = examMetadataModule.state.examMetadata.value
    if (isPromise(examMetadata)) {
        return {}
    } else {
        return examMetadata.reduce<{ [examId: string]: Study.Class.ExamMetadataJSON }>((acc, e) => {
            const isDiscontinued = getIsExamDiscontinued(e)
            if (options?.excludeDiscontinued && isDiscontinued) {
                return acc
            }
            acc[e.objectId] = e
            return acc
        }, {})
    }
}

const getExamMetadata = () => {
    const examMetadata = examMetadataModule.state.examMetadata.value
    if (isPromise(examMetadata)) {
        return []
    } else {
        return examMetadata
    }
}

const getExamMetadataForUEM = (uem: Study.Class.UserExamMetadataJSON) => {
    const examMetadata = getExamMetadata()
    if (uem.examMetadata) {
        return examMetadata.find(em => em.objectId === uem.examMetadata?.objectId)
    } else {
        return examMetadata.find(em => em.examGuid === uem.examGuid)
    }
}

const getMostRecentExamMetadata = () => {
    const examMetadata = examMetadataModule.state.examMetadata.value
    if (isPromise(examMetadata)) {
        return []
    } else {
        return uniqBy(
            e => e.examGuid, 
            [ ...examMetadata ].sort((a, b) => -a.version.localeCompare(b.version, undefined, { numeric: true }))
        )
    }
}

const getMostRecentExamMetadataById = (options?: { excludeDiscontinued?: boolean }) => {
    return getMostRecentExamMetadata().reduce<{ [examId: string]: Study.Class.ExamMetadataJSON }>((acc, e) => {
        const isDiscontinued = getIsExamDiscontinued(e)
        if (options?.excludeDiscontinued && isDiscontinued) {
            return acc
        }
        if (e) {
            acc[e.objectId] = e
        }
        return acc
    }, {})
}

const getMostRecentExamForExamGuid = (examGuid: string) => {
    const exams = Object.values(getExamMetadataById())
    const mostRecentExam = [ ...exams ]
        .filter(exam => exam.examGuid === examGuid)
        .sort((a, b) => -a.version.localeCompare(b.version, undefined, { numeric: true }))[0]
    return mostRecentExam
}

const getCurrentExamMetadata = () => {
    const user = userModule.state.user
    const userExamMetadata = userExamMetadataModule.getters.getCurrentUserExamMetadata()
    const examGuid = userExamMetadata?.examGuid
    const examMetadata = user 
        && userExamMetadata 
        && examMetadataModule.getters.getExamMetadata()
            .find(e => e.objectId === userExamMetadata.examMetadata?.objectId || (
                e.examGuid === examGuid 
                && (
                    !userExamMetadata.examVersion
                    || e.version.startsWith(`${userExamMetadata.examVersion?.split('.')[0]}.`)
                ))
            )

    return examMetadata || (examGuid && getMostRecentExamForExamGuid(examGuid)) || null
}

const getLevelsInSubject = (subjectName: string | undefined) => {
    const subjectsWithLevels = examMetadataModule.state.subjectsWithLevels
    return subjectsWithLevels.find(subjectWithLevels => subjectWithLevels.subjectName === subjectName)?.levels 
}

const getExamVersions = () => {
    const uem = userExamMetadataModule.getters.getCurrentUserExamMetadata()
    const exam = examMetadataModule.getters.getCurrentExamMetadata()
    examMetadataModule.getters.getCurrentExamMetadata()
    const examBundle = bundleModule.getters.getBundles().find(b => b.exams.find(e => e.objectId === exam?.objectId))
    if (!uem || !uem) {
        return undefined
    }

    return examMetadataModule.getters.getExamMetadata()
        .filter(e =>
            e.examGuid === uem?.examGuid 
            && (
                examBundle?.exams.find(be => e.objectId === be.objectId)
                || uem.examVersion?.startsWith(`${e.version.split('.')[0]}.`)
            )
        )
        .sort((a, b) => 
            a.objectId === uem?.objectId 
                ? 1 
                : -a.version.localeCompare(b.version, undefined, { numeric: true })
        )
}

const getUemMajorAndLatestMajorDifference = () => {
    const examVersions = examMetadataModule.getters.getExamVersions()
    const uem = userExamMetadataModule.getters.getCurrentUserExamMetadata()
    if (examVersions && examVersions.length > 1 && uem) {
        // check for latest exam major version
        const examCurrentMajorVersion = examVersions[0].version.split('.')[0]
        // check the user's current exam major version
        const userCurrentMajorVersion = uem?.examVersion?.split('.')[0] || '1'
        // number of versions between latest exam major version and user's current version
        const numberOfNewMajorVersions = Number(examCurrentMajorVersion) - Number(userCurrentMajorVersion)
        return numberOfNewMajorVersions
    }

    return null
}

const getDiscontinuedDateForExam = (examMetadata: Study.Class.ExamMetadataJSON) => {
    if (examMetadata.sunsetAt?.iso) {
        // We want 2025-01-01T00:00:00.000Z to be treated like Jan 1 in the user's timezone
        const sunsetAtIsoDateMinusZ = examMetadata.sunsetAt?.iso.slice(0, -1)
        const date = new Date(sunsetAtIsoDateMinusZ)
        date.setMonth(date.getMonth() + 1)
        return date
    }
    return null
}

const getIsExamDiscontinued = (examMetadata: Study.Class.ExamMetadataJSON) => {
    const discontinuedDate = getDiscontinuedDateForExam(examMetadata)
    if (discontinuedDate) {
        const isAfterDiscontinuedDate = new Date().getTime() - discontinuedDate.getTime() > 0
        return isAfterDiscontinuedDate
    }
    return false
}

export default {
    getExamMetadataById,
    getMostRecentExamForExamGuid,
    getMostRecentExamMetadataById,
    getMostRecentExamMetadata,
    getCurrentExamMetadata,
    getExamMetadata,
    getExamMetadataForUEM,
    getLevelsInSubject,
    getExamVersions,
    getUemMajorAndLatestMajorDifference,
    getDiscontinuedDateForExam,
    getIsExamDiscontinued,
}

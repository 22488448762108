<template>
    <Portal to="modal">
        <ModalContainer key="inviteModal">
            <template #head>
                <div class="invite__head">
                    <img
                        class="invite__logo"
                        src="@/assets/logos/logo.svg"
                        alt="Pocket Prep Logo"
                    >
                    <div class="invite__head-right">
                        <RouterLink
                            v-slot="{ navigate }"
                            custom
                            :to="signInHref"
                        >
                            <PocketLink
                                class="invite__have-account-link"
                                type="tertiary-small"
                                :href="signInHref"
                                @click="navigate"
                                @mousedown.prevent
                            >
                                I have an account
                            </PocketLink>
                        </RouterLink>
                        <span>•</span>
                        <PocketLink
                            class="invite__help-link"
                            type="tertiary-small"
                            @click="showIntercom"
                            @keydown.enter="showIntercom"
                            @mousedown.prevent
                        >
                            Help
                        </PocketLink>
                    </div>
                </div>
            </template>
            <div class="invite">
                <LeftBlob class="invite__left-blob" />
                <RightBlob class="invite__right-blob" />
                <div class="invite__title">
                    Activate Your License Code
                </div>
                <div
                    v-if="mappedExams.length > 1"
                    class="invite__info"
                >
                    {{ orgName }} has given you <span class="invite__premium-access">premium access</span> to
                    <span v-if="license" class="invite__premium-access">
                        prepare for {{  mappedExams.length }} exams
                    </span>.
                    We need some basic information to create your account.
                </div>
                <div
                    v-else
                    class="invite__info"
                >
                    {{ orgName }} has provided
                    <span class="invite__premium-access">premium access to prepare for </span>
                    <span
                        v-if="license"
                        class="invite__exam-name"
                    >
                        the {{ license.examName }}.
                    </span>
                    <span
                        v-else
                        class="invite__exam-name"
                    >
                        your exam.
                    </span>
                    We need some basic information to create your account.
                </div>
                <PocketInput
                    v-model="firstName"
                    class="invite__first-name"
                    label="First Name"
                    :disabled="isLoading"
                    :error="errorFields.includes('firstName')"
                    @keydown.enter="submitAccountDetails"
                />
                <PocketInput
                    v-model="lastName"
                    class="invite__last-name"
                    label="Last Name"
                    :disabled="isLoading"
                    :error="errorFields.includes('lastName')"
                    @keydown.enter="submitAccountDetails"
                />
                <PocketSelect
                    v-if="licenseCode && !errors.length && mappedExams.length > 1"
                    class="invite__select-exam"
                    v-model="exam"
                    label="Select Exam (You can switch anytime)"
                    :data="mappedExams"
                />
                <div class="invite__email-container">
                    <PocketInput
                        v-model="email"
                        class="invite__email"
                        label="Email"
                        field-type="email"
                        :disabled="emailQueryParam ? true : false"
                        :error="errorFields.includes('email')"
                        @keydown.enter="submitAccountDetails"
                    />
                    <div v-if="emailQueryParam" class="invite__email-tip">
                        <span class="invite__email-tip-title">
                            Your license code is associated with this email.
                        </span>
                        <div class="invite__email-tip-description">
                            If you already have an account that you would prefer to use
                            <!-- eslint-disable max-len -->
                            <PocketLink
                                class="invite__email-tip-link"
                                target="_blank"
                                href="http://help.orders.pocketprep.com/en/articles/5416702-how-do-i-claim-a-pocket-prep-license"
                            >
                                click here
                            </PocketLink>
                        <!-- eslint-enable max-len -->
                        </div>
                    </div>
                </div>
                <PocketInput
                    v-if="!licenseQueryParam"
                    v-model="licenseCode"
                    class="invite__license"
                    label="License Code"
                    :disabled="isLoading"
                    :error="errorFields.includes('license')"
                    @keydown.enter="submitAccountDetails"
                    @focusout="validateLicenseCode"
                />
                <PocketInput
                    v-if="createPassword"
                    v-model="password"
                    class="invite__password"
                    label="Create Password (6+ characters)"
                    field-type="password"
                    :disabled="isLoading"
                    :error="errorFields.includes('password')"
                    @keydown.enter="submitAccountDetails"
                />
                <Errors
                    v-if="errors.length"
                    class="invite__errors"
                    :errors="errors"
                >
                    <template #error="{ error }">
                        <template v-if="error === 'Account already exists for this username.'">
                            It looks like you already have an account. Click <PocketLink :href="signInHref">
                                here
                            </PocketLink> to sign in 
                            and complete the license activation process.
                        </template>
                        <template v-else>
                            {{ error }}
                        </template>
                    </template>
                </Errors>
                <div class="invite__password-toggle-container">
                    <template v-if="!createPassword">
                        <RouterLink
                            v-slot="{ href, navigate }"
                            custom
                            :to="{
                                name: 'invite',
                                query: {
                                    ...$route.query,
                                    type: 'password',
                                }
                            }"
                        >
                            <PocketLink
                                class="invite__create-password-link"
                                type="tertiary-small"
                                :disabled="isLoading"
                                :href="href"
                                @click="navigate"
                            >
                                Create a password
                            </PocketLink>
                        </RouterLink> instead of using a link to sign in
                    </template>
                    <template v-else>
                        <RouterLink
                            v-slot="{ href, navigate }"
                            custom
                            :to="{
                                name: 'invite',
                                query: {
                                    ...$route.query,
                                    type: undefined,
                                }
                            }"
                        >
                            <PocketLink
                                class="invite__use-magic-email-link"
                                type="tertiary-small"
                                :disabled="isLoading"
                                :href="href"
                                @click="navigate"
                            >
                                Sign in with a magic link
                            </PocketLink>
                        </RouterLink> instead of creating a password
                    </template>
                </div>
                <div class="invite__legal">
                    <Checkbox
                        v-model="agreed"
                        class="invite__legal-checkbox"
                        :disabled="isLoading"
                    />
                    <div class="invite__legal-text">
                        I agree to the

                        <a
                            target="_blank"
                            :href="`https://www.pocketprep.com/privacy-policy?${buildUTMParamString({
                                utm_campaign: 'app_settings',
                                utm_content: 'invite_privacy_policy'
                            })}`"
                            class="invite__legal-text-link"
                        >privacy policy</a>
                        and
                        <a
                            target="_blank"
                            :href="`https://www.pocketprep.com/terms-of-service?${buildUTMParamString({
                                utm_campaign: 'app_settings',
                                utm_content: 'invite_terms'
                            })}`"
                            class="invite__legal-text-link"
                        >terms of service</a>
                    </div>
                </div>
                <PocketButton
                    class="invite__submit"
                    :disabled="!firstName.trim() || !lastName.trim() || !email || !agreed 
                        || (createPassword && !password)"
                    :is-loading="isLoading"
                    @click="submitAccountDetails"
                    @keydown.enter="submitAccountDetails"
                >
                    Start Studying <Icon class="invite__submit-arrow" type="arrow" />
                </PocketButton>
            </div>
        </ModalContainer>
    </Portal>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-facing-decorator'
import * as Sentry from '@sentry/browser'
import UIKit from '@pocketprep/ui-kit'
import { isSupportWindow, isValidEmail } from '@/utils'
import LeftBlob from '@/assets/onboarding/LeftBlob.vue'
import RightBlob from '@/assets/onboarding/RightBlob.vue'
import type { Study } from '@pocketprep/types'
import { examMetadataModule } from '@/store/examMetadata/module'
import { licenseModule } from '@/store/license/module'
import { userModule } from '@/store/user/module'
import { toastModule } from '@/store/toast/module'
import { buildUTMParamString } from '@/store/utils'

@Component({
    components: {
        PocketInput: UIKit.Input,
        PocketButton: UIKit.Button,
        PocketLink: UIKit.Link,
        PocketSelect: UIKit.Select,
        Icon: UIKit.Icon,
        Checkbox: UIKit.Checkbox,
        Errors: UIKit.Errors,
        ModalContainer: UIKit.ModalContainer,
        LeftBlob,
        RightBlob,
    },
})
export default class Invite extends Vue {
    errors: string[] = []
    errorFields: string[] = []
    firstName = ''
    lastName = ''
    email = ''
    agreed = false
    password = ''
    createPassword = false
    isValidEmail = isValidEmail
    isLoading = false
    license: Study.Class.LicenseJSON | null = null
    licenseCode: string | null = null
    orgName = 'Your school/organization'
    
    // For exam dropdown
    exam: null | { value: string; label: string } = null

    get mappedExams () {
        const exams: string[] = []
        if (this.license) {
            exams.push(this.license.examName)
            if (this.license.linkedLicenses) {
                this.license.linkedLicenses.forEach(linkedLicense => {
                    const ll = linkedLicense as Study.Class.LicenseJSON // Type to extract the examName
                    exams.push(ll.examName)
                })
            }
        }
        return exams.map(e => ({
            label: e,
            value: e,
        }))
    }

    get licenseFromExam () {
        let license = this.license
        if (this.license?.linkedLicenses && this.exam?.value) {
            // Has linkedLicenses AND a selected exam, so we have to find the license in the structure
            const selectedExam = this.license.linkedLicenses.find(l => {
                const linkedLicense = l as Study.Class.LicenseJSON
                return linkedLicense.examName === this.exam?.value
            })
            if (selectedExam) {
                // Only assign if we find it
                license = selectedExam as Study.Class.LicenseJSON
            }
        }
        return license
    }

    get emailQueryParam () {
        return typeof this.$route.query?.email === 'string' ? this.$route.query.email : ''
    }

    get licenseQueryParam () {
        return typeof this.$route.query?.license === 'string' ? this.$route.query.license : ''
    }

    get signInHref () {
        return this.$router.resolve({
            name: 'sign-in',
            query: {
                license: this.licenseCode,
                email: this.email,
                ...(this.firstName && { firstName: this.firstName }),   // Conditionally adds firstName property
                ...(this.lastName && { lastName: this.lastName }),      // Conditionally adds lastName property
                ...this.$route.query,
            },
        }).href
    }

    async mounted () {
        this.isLoading = true
        await userModule.actions.signOut()
        await examMetadataModule.actions.fetchExamMetadata()

        this.email = this.emailQueryParam
        this.licenseCode = this.licenseQueryParam

        if (this.licenseCode) {
            await this.validateLicenseCode()
        }

        this.createPassword = this.$route.query?.type === 'password'
        this.isLoading = false
    }

    buildUTMParamString = buildUTMParamString

    @Watch('$route')
    routeChanged (route: Invite['$route']) {
        this.errors = []
        this.errorFields = []
        this.password = ''
        this.createPassword = route.query?.type === 'password'
    }

    showIntercom () {
        if (import.meta.env.VUE_APP_INTERCOM_APP_ID && !isSupportWindow()) {
            window.Intercom('show')
        }
    }

    async validateLicenseCode () {
        this.errors = []
        this.errorFields = []
        const licenseFromExam = this.licenseFromExam
        const licenseCode = licenseFromExam?.licenseKey || this.licenseCode
        try {
            if (licenseCode) {
                const [ validLicense, orgName ] = await Promise.all([
                    licenseModule.actions.validateLicense(licenseCode),
                    licenseModule.actions.fetchOrgNameFromLicenseCode(licenseCode),
                ])
                this.orgName = orgName || this.orgName
                this.license = validLicense
            } else {
                this.errorFields.push('license')
                this.errors.push('Please enter a license code')
            }
        } catch (err) {
            this.errors.push((err as Error).toString())
            this.errorFields.push('license')
        }
    }

    async submitAccountDetails () {
        this.errors = []
        this.errorFields = []
        this.isLoading = true

        try {
            // validate license
            await this.validateLicenseCode()

            if (!this.license?.examGuid && !this.errorFields.includes('license')) {
                // A license with no examGuid probably came from legacy bulk purchase, which should be inactive
                // If this happens, we should stop the process here and flag it for review
                const legacyLicenseError = new Error(`Invalid License: ${this.licenseCode}`)
                Sentry.captureException(`Legacy License error: ${this.licenseCode}`)
                throw legacyLicenseError
            }

            if (!this.firstName) {
                this.errorFields.push('firstName')
                this.errors.push('Please enter a first name.')
            }
            
            if (!this.lastName) {
                this.errorFields.push('lastName')
                this.errors.push('Please enter a last name.')
            }

            if (!this.isValidEmail(this.email)) {
                this.errorFields.push('email')
                this.errors.push('Invalid email address.')
            }

            if (this.createPassword && this.password.length < 6) {
                this.errorFields.push('password')
                this.errors.push('Password must be at least 6 characters.')
            }

            if (!this.agreed) {
                this.errors.push('You must agree to the terms of service and privacy policy to continue.')
            }

            if (!this.errors.length && this.license?.examGuid) {
                // Licenses don't specify exam versions, so we get the most recent
                const exam = examMetadataModule.getters.getMostRecentExamForExamGuid(this.license.examGuid)
                if (exam) {
                    await userModule.actions.createUser({
                        email: this.email,
                        firstName: this.firstName,
                        lastName: this.lastName,
                        password: this.password,
                        exam,
                    })
                }

                await licenseModule.actions.activateLicense(this.license)
                await this.$router.push({ name: 'study' })
                toastModule.actions.displayToast({
                    title: 'License code activated.',
                    subtext: `You are now studying for the ${this.license.examName} exam. Switch exams via the upper
                    left menu dropdown.`,
                })
            }
        } catch (err) {
            const error = (err as { error?: string }).error || (err as { message?: string }).message
            if (!err) {
                this.errors.push('Unable to create account.')
            } else if (error) {
                this.errors.push(error)
            } else if (err instanceof Error || typeof err === 'string') {
                this.errors.push(err.toString())
            }
        } finally {
            this.isLoading = false
        }
    }
}
</script>
<style lang="scss" scoped>
.invite {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: rgba($gray-background, 0.4);
    width: 964px;
    min-height: 584px;

    @include breakpoint (brown-bear) {
        width: 740px;
    }

    @include breakpoint (black-bear) {
        width: 100%;
        min-height: 100%;
    }

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    &__head-right {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            color: $pewter;
            margin: 0 8px;
            display: block;
        }
    }

    &__logo {
        height: 27px;
    }

    &__left-blob {
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 0 0 0 12px;

        @include breakpoint (black-bear) {
            display: none;
        }
    }

    &__right-blob {
        position: absolute;
        top: 0;
        right: 0;

        @include breakpoint (black-bear) {
            display: none;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 26px;
        line-height: 31px;
        margin-top: 46px;
        margin-bottom: 9px;
    }

    &__info {
        width: 320px;
        color: $slate-03;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        margin-bottom: 34px;
    }

    &__premium-access {
        font-weight: 600;
    }

    &__exam-name {
        font-weight: 600;
    }

    &__select-exam,
    &__first-name,
    &__last-name {
        width: 320px;
        margin-bottom: 19px;
    }

    &__email-container {
        position: relative;
        color: $slate-03;
    }

    &__email {
        width: 320px;
        margin-bottom: 16px;
    }

    &__email-tip {
        position: absolute;
        width: 208px;
        right: -258px;
        top: 5px;
        line-height: 18px;
        font-size: 13px;

        @include breakpoint (brown-bear) {
            width: 171px;
            right: -189px;
            top: 10px;
        }

        @include breakpoint (black-bear) {
            position: relative;
            width: 294px;
            top: auto;
            right: auto;
            margin-bottom: 30px;
            margin-left: 3px;
        }

        &::before {
            content: '';
            position: absolute;
            left: -68px;
            top: -9px;
            background: url(@/assets/onboarding/tip-arrow.svg) no-repeat center center;
            width: 65px;
            height: 14px;
            transform: rotate(-10deg);

            @include breakpoint (black-bear) {
                display: none;
            }
        }
    }

    &__email-tip-title {
        margin-bottom: 6px;
        font-weight: 600;
        display: block;

        @include breakpoint (brown-bear) {
            margin-bottom: 8px;
        }
    }

    &__email-tip-description {
        @include breakpoint(black-bear) {
            width: 262px;
        }
    }

    &__email-tip-link {
        font-size: 13px !important;
    }

    &__password {
        width: 320px;
        margin-bottom: 16px;
    }

    &__license {
        width: 320px;
        margin-bottom: 16px;
    }

    &__password-toggle-container {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 32px;
        margin-left: 11px;

        @include breakpoint (black-bear) {
            margin-bottom: 24px;
        }
    }

    &__create-password-link,
    &__use-magic-email-link {
        font-size: 13px !important;
        font-weight: 500 !important;
    }

    &__legal {
        font-size: 13px;
        line-height: 18px;
        margin-left: 4px;
        display: flex;
        align-items: center;
    }

    &__legal-checkbox {
        margin-right: 9px;
        vertical-align: middle;
        margin-bottom: 2px;
    }

    &__legal-text-link {
        color: $brand-blue;
        text-decoration: none;
    }

    &__errors {
        box-sizing: border-box;
        width: 320px;
        margin-bottom: 16px;
    }

    &__submit {
        margin: 22px auto 40px;
        display: block;

        @include breakpoint (black-bear) {
            margin-bottom: 64px;
        }
    }

    &__submit-arrow {
        margin-left: 10px;
    }
}
</style>
<script setup lang="ts">
import { userModule } from '@/store/user/module'
import { computed, ref } from 'vue'
import UIKit from '@pocketprep/ui-kit'
const {
    ModalContainer,
    Button: PocketButton,
} = UIKit

const props = defineProps<{
    examName: string | undefined
    discontinuedDate: string | null
}>()

const emit = defineEmits<{
    close: []
    'reviewExamVersions': []
}>()

const displayExamName = props.examName
const displayDate = props.discontinuedDate
const isLoading = ref(false)

const isDarkMode = computed(() => {
    return userModule.state.settings.isDarkMode
})

const reviewExamVersions = () => {
    isLoading.value = true
    emit('reviewExamVersions')
}
</script>

<template>
    <Portal to="modal">
        <ModalContainer
            key="sunsetExamVersionModal"
            :show-close-button="true"
            :is-dark-mode="isDarkMode"
            @close="emit('close')"
        >
            <div class="sunset-exam-version-modal">
                <div class="sunset-exam-version-modal__title">
                    Exam Version Discontinued
                </div>
                <div class="sunset-exam-version-modal__content">
                    {{ displayExamName }} launched a new version of their exam. 
                    Our prep for the previous version will be discontinued on {{ displayDate }}.
                </div>
                <div 
                    class="sunset-exam-version-modal__actions"
                >
                    <PocketButton
                        class="sunset-exam-version-modal__close-btn"
                        :isDarkMode="isDarkMode"
                        type="secondary"
                        @click="emit('close')"
                    >
                        Close
                    </PocketButton>
                    <PocketButton
                        class="sunset-exam-version-modal__switch-version-btn"
                        :isDarkMode="isDarkMode"
                        :is-loading="isLoading"
                        @click="reviewExamVersions"
                    >
                        Switch Exam Version
                    </PocketButton>
                </div>
            </div>
        </ModalContainer>
    </Portal>
</template>

<style lang="scss" scoped>
.sunset-exam-version-modal {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0.3px;
        text-align: center;
        margin-top: 36px;
        margin-bottom: 12px;
    }

    &__content {
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        width: 298px;
        margin: 0 38px 32px 38px;
    }

    &__actions {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__close-btn,
    &__switch-version-btn {
        margin-bottom: 24px;
        margin-left: 6px;
    }
}
</style>

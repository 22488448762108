import { quizModule } from '@/store/quiz/module'
import type { Study } from '@pocketprep/types'
import { questionModule } from '@/store/question/module'
import { isPromise } from '@/store/utils'
import { getStartOfWeek } from '@/utils'

const getAnsweredQuestions = () => {
    const questions = quizModule.state.answeredQuestions.value
    if (isPromise(questions)) {
        return []
    } else {
        return questions
    }
}

type TGetLatestAnswersPayload = { 
    quizId?: string
    questionFilter: 'all' | 'accessible' | 'free' | 'mock' // accessible: for free users is free + todays qotd
    sinceDate?: Date
    quizMode?: number
}
const getLatestAnswers = ({ quizId, questionFilter, sinceDate, quizMode }: TGetLatestAnswersPayload) => {
    const quizzes = quizModule.state.quizzes
    const serialQuestionInfoLib = questionModule.getters.getSerialQuestionInfoLib({ questionFilter })

    return quizzes.reduce((acc, quiz) => {
        // If quizId is passed, only use the answers from that quiz
        if (quizId && quiz.objectId !== quizId) {
            return acc
        }

        // If quizMode is passed, only use quizzes with that mode
        if (typeof quizMode === 'number' && quiz.mode !== quizMode) {
            return acc
        }

        // If sinceDate is passed, ignore quizzes from before the date
        if (sinceDate && new Date(quiz.createdAt).getTime() < sinceDate.getTime()) {
            return acc
        }

        quiz.answers.forEach((answer, index) => {
            if (
                serialQuestionInfoLib[answer.questionSerial]
                && (
                    !(answer.questionSerial in acc)
                    || (new Date(acc[answer.questionSerial].answerDate) < new Date(quiz.createdAt))
                )
            ) {
                acc[answer.questionSerial] = {
                    ...answer,
                    // add index to date.getTime to ensure order is maintained when sorting
                    answerDate: (new Date(quiz.createdAt).getTime()) + index,
                }
            }
        })
        return acc
    }, {} as { [serial: string]: Study.Cloud.IQuizAnswer & { answerDate: number } })
}

const getWeeklyAnswerHistory = () => {
    const sortedQuizzes = [ ...getQuizzes() ]
        .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())  // Oldest to newest

    // Go through all the quizzes and count the questions each week
    const weeklyAnswerHistory = sortedQuizzes.reduce((acc, quiz) => {
        const quizDate = new Date(quiz.createdAt)
        const quizStartOfWeek = getStartOfWeek(quizDate)

        const startOfWeekMs = quizStartOfWeek.getTime()
        const existingActivity = acc.find(activity => activity.startOfWeekMs === startOfWeekMs)
        // Only count quiz answers with selected choices (ignores unanswered mock exam questions, for example)
        const quizAnswerCount = quiz.answers.filter(answer => answer.selectedChoices.length).length
        if (existingActivity) {
            existingActivity.answerCount += quizAnswerCount
        } else {
            acc.push({
                startOfWeekMs,
                answerCount: quizAnswerCount,
            })
        }
        return acc
    }, [] as {startOfWeekMs: number; answerCount: number }[])
    return weeklyAnswerHistory
}

const getCurrentWeekAnswerCount = () => {
    const startOfCurrentWeekMs = getStartOfWeek(new Date()).getTime()
    const weeklyAnswerHistory = getWeeklyAnswerHistory()
    const currentWeekHistory = weeklyAnswerHistory
        .find(weekHistory => weekHistory.startOfWeekMs === startOfCurrentWeekMs)
    return currentWeekHistory?.answerCount || 0
}

type TSubjectStat = { 
    [subjectName: string]: { 
        correctCount: number
        incorrectCount: number
        totalCount: number
        name: string 
        id: string
    } 
}
const getSortedSubjectStats = () => {
    const serialQuestionInfoLib = questionModule.getters.getSerialQuestionInfoLib({ questionFilter: 'all' })
    const subjectStats = Object.values(quizModule.getters.getLatestAnswers({ questionFilter: 'all' }))
        .reduce<TSubjectStat>(
        (acc, answer) => {
            if (!serialQuestionInfoLib) {
                return acc
            }

            const subjectName = serialQuestionInfoLib[answer.questionSerial]?.subject
            const subjectId = serialQuestionInfoLib[answer.questionSerial]?.subjectId

            if (!subjectName || !subjectId) {
                return acc
            }

            if (!(subjectId in acc)) {
                acc[subjectId] = {
                    name: subjectName,
                    id: subjectId,
                    correctCount: 0,
                    incorrectCount: 0,
                    totalCount: 0,
                }
            }

            const subjectObject = acc[subjectId]
            if (subjectObject) {
                subjectObject.totalCount++
                if (answer.isCorrect) {
                    subjectObject.correctCount++
                } 
                if (subjectObject && answer.isCorrect) {
                    subjectObject.incorrectCount ++
                }
            }
            return acc
        }, {})
    
    return Object.values(subjectStats)
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))
        .sort((a, b) => (a.correctCount / a.totalCount) - (b.correctCount / b.totalCount))
}

const getActiveQuiz = () => {
    return quizModule.state.activeQuiz
}

const getQuizzes = () => {
    const quizzes = quizModule.state.quizzes

    return [ ...quizzes ]
}

const getQuizById = (quizId: string) => {
    const quizzes = quizModule.state.quizzes
    return quizzes.find(quiz => quiz.objectId === quizId) || null
}

const getStreakCount = ({ filterQuizMode, quizModeNum }: {
    filterQuizMode: boolean
    quizModeNum?: number
}) => {
    const quizzes = [ ...quizModule.state.quizzes ]
    const quizArray = filterQuizMode && quizModeNum ? quizzes.filter(q => q.mode === quizModeNum) : quizzes
    return quizArray
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        .reduce((acc, quiz) => {
            const quizCreatedAtDate = new Date(quiz.createdAt)
            const quizCreatedAtLocaleString = quizCreatedAtDate.toLocaleDateString() // e.g. 1/1/2024
            const compareDate = new Date(quizCreatedAtDate)
            compareDate.setDate(new Date(quizCreatedAtDate).getDate() + 1)  // The day after the last quiz date
            const compareDateLocaleString = compareDate.toLocaleDateString() // e.g. 1/2/2024
            if (
                !acc.datesCounted.includes(quizCreatedAtLocaleString)   // Have we already counted this date?
                && (
                    acc.lastDate === compareDateLocaleString    // Are the dates between quizzes 1 day apart?
                    || new Date().toLocaleDateString() === quizCreatedAtLocaleString   // Was the quiz today?
                )
            ) {
                acc.count++
                acc.datesCounted.push(quizCreatedAtLocaleString)
                acc.lastDate = quizCreatedAtLocaleString
            }
            return acc
        }, { 
            lastDate: new Date().toLocaleDateString(), 
            datesCounted: [], 
            count: 0, 
        } as { lastDate: string; datesCounted: string[]; count: number }).count
}

const getPrebuiltQuizzes = () => {
    const prebuiltQuizzes = quizModule.state.prebuiltQuizzes

    if (isPromise(prebuiltQuizzes)) {
        return {}
    } else {
        return Object.fromEntries(prebuiltQuizzes.map(pq => ([ pq.objectId, pq ])))
    }
}

export default {
    getAnsweredQuestions,
    getLatestAnswers,
    getWeeklyAnswerHistory,
    getCurrentWeekAnswerCount,
    getSortedSubjectStats,
    getActiveQuiz,
    getQuizzes,
    getQuizById,
    getStreakCount,
    getPrebuiltQuizzes,
}

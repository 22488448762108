<template>
    <div class="uti-account-details">
        <div class="uti-account-details__info">
            To get started with lifetime access to Pocket
            Prep's ASE exam prep, please complete your account and purchase info.
        </div>
        <PocketInput
            v-model="firstName"
            class="uti-account-details__input"
            :class="{
                'uti-account-details__input--password': showPasswordSignIn,
            }"
            field-type=""
            label="First Name"
            aria-required="true"
            :error="errorFields.length"
            :disabled="currentUser && currentUser.firstName"
            @keydown.enter="submitAccountDetails"
        />
        <PocketInput
            v-model="lastName"
            class="uti-account-details__input"
            field-type=""
            label="Last Name"
            aria-required="true"
            :error="errorFields.length"
            :disabled="currentUser && currentUser.lastName"
            @keydown.enter="submitAccountDetails"
        />
        <PocketSelect
            v-if="campusOptions.length"
            v-model="campus"
            class="uti-account-details__input"
            :class="{
                'uti-account-details__input--password': showPasswordSignIn,
            }"
            label="Campus"
            placeholder="Select Campus"
            :data="campusOptions"
        />
        <PocketInput
            v-model="email"
            class="uti-account-details__input"
            :class="{
                'uti-account-details__input--password': showPasswordSignIn,
            }"
            field-type="email"
            label="Email"
            :error="emailError"
            :disabled="currentUser && currentUser.username"
            @keydown.enter="submitAccountDetails"
        />
        <PocketInput
            v-if="createPassword"
            ref="passwordInput"
            v-model="password"
            :error="errorFields.length > 0"
            class="uti-account-details__input"
            aria-required="true"
            label="Create Password (6+ characters)"
            field-type="password"
            :disabled="currentUser"
            @keydown.enter="submitAccountDetails"
        />
        <Errors
            v-if="errors.length"
            class="uti-account-details__errors"
            :errors="errors"
        />
        <div v-if="!currentUser" class="uti-account-details__create-password">
            <template v-if="!createPassword">
                <PocketButton
                    type="tertiary-small uti-account-details__create-password-link"
                    @click="toggleCreatePassword"
                    @keydown.enter="toggleCreatePassword"
                    @mousedown.prevent
                >
                    Create a password
                </PocketButton>
                instead of using a link to sign in
            </template>
            <template v-else>
                <PocketButton
                    type="tertiary-small uti-account-details__create-password-link"
                    @click="toggleCreatePassword"
                    @keydown.enter="toggleCreatePassword"
                    @mousedown.prevent
                >
                    Sign in with a link
                </PocketButton>
                instead of creating a password
            </template>
        </div>
        <div class="uti-account-details__legal">
            <Checkbox
                v-model="agreed"
                class="uti-account-details__legal-checkbox"
                :disabled="agreeDisabled"
                aria-label="I agree to the privacy policy and terms of service, required"
            />
            <div class="uti-account-details__legal-text">
                I agree to the
                <a
                    target="_blank"
                    :href="`https://www.pocketprep.com/privacy-policy?${buildUTMParamString({
                        utm_campaign: 'app_settings',
                        utm_content: 'account_details_privacy_policy'
                    })}`"
                    class="uti-account-details__legal-text-link"
                >privacy policy</a>
                and
                <a
                    target="_blank"
                    :href="`https://www.pocketprep.com/terms-of-service?${buildUTMParamString({
                        utm_campaign: 'app_settings',
                        utm_content: 'account_details_terms'
                    })}`"
                    class="uti-account-details__legal-text-link"
                >terms of service</a>
            </div>
        </div>
        <PocketButton
            class="uti-account-details__submit-account-details"
            :disabled="!firstName.trim() 
                || !lastName.trim() || !email || !agreed || !campus || (createPassword && !password)"
            :is-loading="isLoading"
            @click="submitAccountDetails"
            @keydown.enter="submitAccountDetails"
        >
            Continue to Payment <Icon type="arrow" />
        </PocketButton>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Emit, Prop, Watch } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { userModule } from '@/store/user/module'
import { isSupportWindow, isValidEmail } from '@/utils'
import UtiHeader from '@/components/UTI/UtiHeader.vue'
import { examMetadataModule } from '@/store/examMetadata/module'
import { buildUTMParamString } from '@/store/utils'


@Component({
    components: {
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
        UtiHeader,
        PocketInput: UIKit.Input,
        PocketButton: UIKit.Button,
        Icon: UIKit.Icon,
        PocketLink: UIKit.Link,
        PocketSelect: UIKit.Select,
        Checkbox: UIKit.Checkbox,
        Errors: UIKit.Errors,
    },
})
export default class UtiAccountDetails extends Vue {
    @Prop() examGuids!: string[]
    @Prop() orgIdNameLib!: { [orgId: string]: string } | null

    firstName = ''
    lastName = ''
    email = ''
    campus: { label: string; value: string } | null = null
    password = ''
    createPassword = false
    showPasswordSignIn = false
    showPaymentForm = false
    isLoading = false
    emailError = false
    passwordError = false
    showUserDeletedModal = false
    agreed = false
    agreeDisabled = false
    errors: string[] = []
    errorFields: string[] = []
    isValidEmail = isValidEmail

    get currentUser () {
        return userModule.state.user
    }

    get examMetadata () {
        return examMetadataModule.getters.getExamMetadata()
    }

    get exam () {
        return this.examMetadata.find(examMetadata => examMetadata.examGuid === this.examGuids[0])
    }

    get campusOptions () {
        if (!this.orgIdNameLib) {
            return []
        }
        return Object.entries(this.orgIdNameLib).map(orgIdName => ({
            label: orgIdName[1],
            value: orgIdName[0],
        }))
    }

    async mounted () {
        const existingUser = userModule.state.user
        this.firstName = existingUser?.firstName || ''
        this.lastName = existingUser?.lastName || ''
        this.email = existingUser?.email || ''
        this.createPassword = !!this.password
    }

    buildUTMParamString = buildUTMParamString

    showIntercom () {
        if (import.meta.env.VUE_APP_INTERCOM_APP_ID && !isSupportWindow()) {
            window.Intercom('show')
        }
    }

    toggleCreatePassword () {
        this.createPassword = !this.createPassword
        
        if (!this.createPassword) {
            this.password = ''
        } else {
            setTimeout(() => {
                const passwordInputRef = this.$refs.passwordInput as typeof UIKit.Input | undefined
                const passwordInputEl = (passwordInputRef
                    && '$el' in passwordInputRef
                    && passwordInputRef.$el) as HTMLElement
                const inputEl = passwordInputEl?.querySelector('input')
                inputEl?.focus()
            }, 1)
        }
    }

    async submitAccountDetails () {
        this.errors = []
        this.errorFields = []
        this.isLoading = true

        try {
            const currentUser = userModule.state.user
            if (!this.firstName || !this.lastName) {
                if (!this.firstName) {
                    this.errorFields.push('firstName')
                }
                if (!this.lastName) {
                    this.errorFields.push('lastName')
                }
                this.errors.push('Please enter a first and last name.')
            }

            if (!this.agreed) {
                this.errors.push('You must agree to the terms of service and privacy policy to continue.')
            }

            if (!this.isValidEmail(this.email)) {
                this.errorFields.push('email')
                this.errors.push('Invalid email address.')
            }

            if (this.createPassword && this.password.length < 6) {
                this.errorFields.push('password')
                this.errors.push('Password must be at least 6 characters.')
            }

            if (!this.errors.length && this.exam) {
                // check if there are multiple exam versions and, if so, mark as seen so we don't
                // show user the upgrade modal

                if (currentUser) {
                    await userModule.actions.updateUser({
                        email: this.email,
                        firstName: this.firstName,
                        lastName: this.lastName,
                        password: this.password,
                        exam: this.exam,
                    })
                } else {
                    await userModule.actions.createUser({
                        email: this.email,
                        firstName: this.firstName,
                        lastName: this.lastName,
                        password: this.password,
                        exam: this.exam,
                    })
                }

                this.emitSubmit({
                    password: this.password,
                })
            }
        } catch (err) {
            const error = (err as { error?: string }).error || (err as { message?: string }).message
            if (!err) {
                this.errors.push('Unable to create or update account.')
            } else if (error) {
                this.errors.push(error)
            } else if (err instanceof Error || typeof err === 'string') {
                this.errors.push(err.toString())
            }
            
        } finally {
            this.isLoading = false
        }
    }

    @Emit('submit')
    emitSubmit (submitInfo: { password: string }) {
        return submitInfo
    }

    @Watch('campus')
    campusChanged () {
        if (this.campus?.value && this.$route.query.orgId !== this.campus.value) {
            this.$router.replace({
                name: 'uti-discount',
                query: {
                    ...this.$route.query,
                    orgId: this.campus.value,
                },
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.uti-account-details {
    width: 320px;
    margin: 9px auto 0;

    &__info {
        width: 320px;
        color: $slate-03;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        margin-bottom: 36px;
    }

    &__input {
        margin-bottom: 17px;

        &--password {
            margin-bottom: 21px;
        }
    }

    &__errors {
        margin-bottom: 17px;
    }
    
    &__create-password {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 24px;
        margin-left: 11px;

        &--error {
            margin-bottom: 20px;
        }
    }

    &__create-password-link {
        font-weight: 500;
        font-size: 13px;
    }

    &__legal {
        font-size: 13px;
        line-height: 18px;
        margin-left: 4px;
        display: flex;
        align-items: center;
    }

    &__legal-checkbox {
        margin-right: 9px;
        vertical-align: middle;
        margin-bottom: 2px;
    }

    &__legal-text-link {
        color: $brand-blue;
        text-decoration: none;
    }

    &__submit-account-details {
        margin: 22px auto 6px;
        display: block;

        svg {
            margin-left: 10px;
        }
    }
}
</style>

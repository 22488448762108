<template>
    <div class="exam-menu">
        <AddExamSidePanel
            v-if="showAddExamSidePanel"
            key="examMenu"
            @close="showAddExamSidePanel = false"
        />
        <Portal v-if="isLoading" to="modal">
            <ModalContainer key="loadingExamModal" :is-dark-mode="isDarkMode">
                <template #modal>
                    <Modal
                        v-dark
                        class="exam-menu__loading"
                        :is-dark-mode="isDarkMode"
                    >
                        <img
                            v-if="isDarkMode"
                            class="exam-menu__loading-gif"
                            src="@/assets/loading/High-Five-animation-2DM.gif"
                        >
                        <img
                            v-else
                            class="exam-menu__loading-gif"
                            src="@/assets/loading/High-Five-animation-2.gif"
                        >
                        <div class="exam-menu__loading-title">
                            Let's do this!
                        </div>
                        <div class="exam-menu__loading-subtitle">
                            Loading your exam...
                        </div>
                    </Modal>
                </template>
            </ModalContainer>
        </Portal>
        <ExamCard
            v-if="currentExamMetadata"
            ref="dropdownBtn"
            :exam="currentExamMetadata"
            theme="silver"
            class="exam-menu__selected-exam"
            :show-menu-arrow="true"
            role="button"
            :aria-expanded="showDropdown ? 'true' : 'false'"
            :is-focusable="true"
            :tabindex="showDropdown ? -1 : 0"
            @click="showDropdown = true"
            @keydown.enter="showExamDropdown()"
            @keydown.esc="hideDropdownAndFocusBtn()"
            @mousedown.prevent
        />
        <ExamMenuDropdown
            v-if="showDropdown"
            :exam-metadata="examMetadata"
            :current-exam-metadata="currentExamMetadata"
            :focus-first-card="shouldFocusFirstCard"
            @addExam="openAddExamSidePanel"
            @clickExamCard="clickExamCard"
            @close="hideDropdownAndFocusBtn()"
        />
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'
import ExamCard from '@/components/Head/ExamCard.vue'
import { userModule } from '@/store/user/module'
import type { Study } from '@pocketprep/types'
import { examMetadataModule } from '@/store/examMetadata/module'
import { bundleModule } from '@/store/bundle/module'
import UIKit from '@pocketprep/ui-kit'
import AddExamSidePanel from '@/components/Settings/AddExamSidePanel.vue'
import ExamMenuDropdown from '@/components/Head/ExamMenuDropdown.vue'
import type { ComponentPublicInstance } from 'vue'
import { quizModule } from '@/store/quiz/module'

@Component({
    components: {
        ExamCard,
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
        AddExamSidePanel,
        ExamMenuDropdown,
    },
})
export default class ExamMenu extends Vue {
    @Prop() currentExamMetadata!: Study.Class.ExamMetadataJSON
    @Prop() examMetadata!: Study.Class.ExamMetadataJSON[]
    showDropdown = false
    isLoading = false
    showAddExamSidePanel = false
    shouldFocusFirstCard = false

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    mounted () {
        examMetadataModule.actions.fetchExamMetadata()
        bundleModule.actions.fetchBundles()
        userModule.actions.fetchUserData()
        document.addEventListener('click', this.clickListener)
    }

    showExamDropdown () {
        this.shouldFocusFirstCard = true
        this.showDropdown = true
    }

    hideDropdownAndFocusBtn () {
        this.showDropdown = false

        if (this.shouldFocusFirstCard) {
            this.$nextTick(() => {
                const dropdownBtn = this.$refs['dropdownBtn'] as ComponentPublicInstance | undefined
                const dropdownBtnEl = dropdownBtn?.$el as HTMLElement | undefined
                dropdownBtnEl?.focus()
                this.shouldFocusFirstCard = false
            })
        }
    }

    openAddExamSidePanel () {
        this.showDropdown = false
        this.showAddExamSidePanel = true
    }

    beforeUnmount () {
        document.removeEventListener('click', this.clickListener)
    }

    clickListener (e: MouseEvent) {
        if (this.showDropdown) {
            const targetEl = e.target as HTMLElement
            const dropdownEl = this.$refs.dropdown as HTMLElement | undefined
            const dropdownBtnEl = this.$refs.dropdownBtn as ComponentPublicInstance | undefined
            if (!dropdownBtnEl?.$el.contains(targetEl) && !dropdownEl?.contains(targetEl)) {
                this.showDropdown = false
            }
        }
    }

    async clickExamCard (exam: Study.Class.ExamMetadataJSON) {
        if (exam.objectId !== this.currentExamMetadata.objectId) {
            this.isLoading = true
            examMetadataModule.state.isSwitchingExams = true
            this.hideDropdownAndFocusBtn()
            await userModule.actions.updateCurrentExam(exam.objectId)
            // make sure when we switch exams we clear out activeQuiz
            // this helps if a user had an activeQuiz to resume for another exam
            quizModule.actions.updateActiveQuiz(null)
            this.emitExamChanged()
            examMetadataModule.state.isSwitchingExams = false
            this.isLoading = false
        } else {
            this.hideDropdownAndFocusBtn()
        }
    }

    @Emit('examChanged')
    emitExamChanged () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.exam-menu {
    position: relative;
    height: 54px;
    color: $brand-black;

    @include breakpoint(brown-bear) {
        width: 268px;
    }

    @include breakpoint(black-bear) {
        width: calc(100% - 54px);
    }

    &__loading {
        position: absolute;
        top: 143px;
        left: 50%;
        transform: translateX(-50%);
        padding: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $brand-black;
        font-weight: 600;

        &--dark {
            color: $white;
            background: $charcoal;
        }

        @include breakpoint(black-bear) {
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__loading-gif {
        height: 184px;
        margin-bottom: 19px;
        margin-right: -20px;
    }

    &__loading-title {
        font-size: 16px;
        line-height: 19px;
    }

    &__loading-subtitle {
        font-size: 14px;
        line-height: 17px;
        opacity: 0.75;
    }

    &__selected-exam {
        border-radius: 6px;
        max-width: 100%;
        border: 1px solid transparent;

        @include breakpoint(black-bear) {
            padding: 0 0 0 4px;
            border: none;
        }

        &:focus::before {
            border: 1px solid $banana-bread;
        }
    }
}
</style>
import type { Study } from '@pocketprep/types'
import * as Sentry from '@sentry/browser'
import { Parse, objPointer } from '@/store/parseUtils'
import { runCloudFunction } from '@/store/parseUtils'
import { resetState } from '@/store/reset'
import { userModule } from '@/store/user/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { quizModule } from '@/store/quiz/module'
import { fetchLoadable, resetLoadable } from '@/store/utils'
import { subscriptionModule } from '@/store/subscription/module'
import { questionModule } from '@/store/question/module'
import { stripeModule } from '@/store/stripe/module'
import type { IUserState } from '@/store/user/state'
import { qotdModule } from '@/store/qotd/module'
import { progressModule } from '@/store/progress/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { globalQuestionMetricModule } from '@/store/globalQuestionMetric/module'
import { mockExamModule } from '@/store/mockExam/module'
import { analyticsModule } from '@/store/analytics/module'
import { bundleModule } from '@/store/bundle/module'
import { referralModule } from '@/store/referral/module'
import type { RouteLocationNormalizedLoaded } from 'vue-router'
import { isSupportWindow } from '@/utils'

const fetchUserData = async (forceFetch?: { userData?: boolean; stripeSubs?: boolean; uem?: boolean }) => {
    // If we don't have a user, don't try to fetch user data
    if (!userModule.state.user) {
        return null
    }

    // If we have a user but can't find their exam, don't try to fetch user data
    await Promise.all([
        examMetadataModule.actions.fetchExamMetadata(),
        userExamMetadataModule.actions.fetchUserExamMetadata(forceFetch?.uem),
    ])

    const currentUEM = userExamMetadataModule.getters.getCurrentUserExamMetadata()
    const examGuid = currentUEM?.examGuid

    if (!examGuid) {
        return null
    }

    // Checking currentExamGuid for legacy support
    if (userModule.state.user && !userModule.state.user?.currentExamGuid) {
        userModule.state.user.currentExamGuid = examGuid
        const userUpdate = new Parse.User({
            objectId: userModule.state.user.objectId,
            currentExamGuid: examGuid,
        })
        await userUpdate.save()
    }
    if (userModule.state.user && !userModule.state.user?.currentUserExamMetadata && currentUEM) {
        userModule.state.user.currentUserExamMetadata = currentUEM
        const userUpdate = new Parse.User({
            objectId: userModule.state.user.objectId,
            currentUserExamMetadata: objPointer(currentUEM.objectId, 'UserExamMetadata'),
        })
        await userUpdate.save()
    }

    const currentExamMetadata = examMetadataModule.getters.getCurrentExamMetadata()
    if (!currentExamMetadata) {
        return null
    }

    return fetchLoadable(userModule.state.userData, async () => {
        const [ userData ] = await Promise.all([
            runCloudFunction<Study.Cloud.fetchUserDataV2>(
                'fetchUserData-v2', 
                {
                    examGuid,
                    examMetadataId: currentExamMetadata.objectId,
                }
            ),
            stripeModule.actions.fetchStripeSubscriptions(forceFetch?.stripeSubs),
        ])

        await refreshParseUser()

        userModule.state.user = userData.user
        quizModule.state.quizzes = userData.quizzes
        subscriptionModule.state.subscriptions = userData.subscriptions
        examMetadataModule.state.subjectsWithLevels = userData.subjectsWithLevels

        if (userData.referralInfo) {
            userModule.state.referralInfo = userData.referralInfo
            localStorage.setItem('referralInfo', JSON.stringify(userData.referralInfo))
        } else {
            const bundle = bundleModule.getters.getCurrentBundle()
            const sub = subscriptionModule.getters.getSubscriptionForExamId()
            // Subscribed premium users (not users studying free exams)
            if (bundle && typeof sub === 'object') {
                const { redeemedUsersCount, code } = await referralModule.actions.fetchReferralInfo(bundle.objectId)
                userModule.state.referralInfo = {
                    redeemedUsersCount,
                    code,
                }
                localStorage.setItem('referralInfo', JSON.stringify({
                    redeemedUsersCount,
                    code,
                }))
            }
        }

        if (
            import.meta.env.VUE_APP_GOOGLE_ANALYTICS_ID
            && userData?.user?.objectId
            && !isSupportWindow()
        ) {
            gtag('config', import.meta.env.VUE_APP_GOOGLE_ANALYTICS_ID, {
                user_id: userData.user.objectId,
            })
        }

        return userData
    }, !!forceFetch)
}

const toggleIsDarkMode = () => {
    updateLocalSettings({
        isDarkMode: !userModule.state.settings.isDarkMode,
    })
}

const updateCurrentExam = async (examMetadataId: string) => {
    const currentUser = userModule.state.user

    if (!currentUser) {
        throw new Error('No user found.')
    }

    await runCloudFunction<Study.Cloud.updateCurrentExam>('updateCurrentExam', {
        examMetadataId,
    })

    // Need to ensure we have the new UEM before updating the user's UEM pointer
    await userExamMetadataModule.actions.fetchUserExamMetadata(true)

    // Need to ensure Parse.User.current() is up to date
    await refreshParseUser()

    // Clear any active quiz for the previous examGuid
    localStorage.removeItem('activeQuiz')

    // fetch all data that needs fetched
    resetLoadable(userModule.state.userData)
    resetLoadable(questionModule.state.serialQuestionInfoLib)
    resetLoadable(qotdModule.state.question)
    resetLoadable(qotdModule.state.globalMetric)
    resetLoadable(progressModule.state.progressByExamGuid)
    resetLoadable(quizModule.state.answeredQuestions)
    resetLoadable(globalQuestionMetricModule.state.globalQuestionMetricsBySerial)
    resetLoadable(mockExamModule.state.mockExams)
    await fetchUserData({ userData: true, uem: true })
    await Promise.all([
        questionModule.actions.fetchSerialQuestionInfoLib(),
        qotdModule.actions.fetchCurrentQotDQuestion(),
        qotdModule.actions.fetchCurrentQotDMetric(),
        progressModule.actions.fetchProgress(),
        globalQuestionMetricModule.actions.fetchGlobalQuestionMetrics(),
        quizModule.actions.fetchAnsweredQuestions(),
    ])

    analyticsModule.actions.updateIntercom()
    await mockExamModule.actions.fetchMockExams()   // Used to need after leanplum, TODO: maybe could move up now
}

const updateLocalSettings = (settings: Partial<IUserState['settings']>) => {
    userModule.state.settings = {
        ...userModule.state.settings,
        ...settings,
    }
    localStorage.setItem('settings', JSON.stringify(userModule.state.settings))
}

const forgotPassword = (email: string) =>
    Parse.User.requestPasswordReset(email.toLowerCase())

export type TUpdateUserPayload = { 
    email?: string
    firstName: string
    lastName: string
    password?: string
    exam?: Study.Class.ExamMetadataJSON 
}
const updateUser = async ({ email, firstName, lastName, password, exam }: TUpdateUserPayload) => {
    const refreshedParseUser = await refreshParseUser()
    const originalUser = JSON.parse(JSON.stringify(refreshedParseUser))

    // if exam passed, check if userExamMetadata exists for it
    if (exam) {
        await userModule.actions.updateCurrentExam(exam.objectId)
    }

    const currentUser = userModule.state.user 
        && (new Parse.User({ objectId: userModule.state.user.objectId }) as unknown as Study.Class.User)

    if (!currentUser) {
        throw new Error('No user found.')
    }

    const saveParams: Partial<Study.Class.UserPayload> & { password?: string } = {
        firstName,
        lastName,
    }

    if (password) {
        saveParams.password = password
    }

    // make sure we are lowerCase emails on user updates
    if (email) {
        saveParams.email = email.toLowerCase()
        currentUser.unset('studyRemindersEmail')
    }

    try {
        await currentUser.save(saveParams as Study.Class.UserPayload)
    } catch (e) {
        currentUser.set({
            email: originalUser.email,
            firstName: originalUser.firstName,
            lastName: originalUser.lastName,
            currentExamGuid: originalUser.currentExamGuid,  // legacy support
            currentUserExamMetadata: originalUser.currentUserExamMetadata,
        })
        throw e
    }

    await fetchUserData({ userData: true })

    analyticsModule.actions.updateIntercom()
}

type TUserPayload = { 
    email: string
    firstName: string
    lastName: string
    password: string
    exam: Study.Class.ExamMetadataJSON
}
const createUser = async ({ email, firstName, lastName, password, exam }: TUserPayload) => {
    const generatePassword = () => {
        const length = 12
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
        let retVal = ''
        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n))
        }
        return retVal
    }

    const lowercaseEmail = email.toLowerCase()
    const newUser: Study.Class.User = await Parse.User.signUp(
        lowercaseEmail,
        password || generatePassword(),
        {
            email: lowercaseEmail,
            firstName,
            lastName,
            currentExamGuid: exam.examGuid,
        }
    )

    userModule.state.user = newUser.toJSON()

    await userExamMetadataModule.actions.upsertUserExamMetadata({
        examGuid: exam.examGuid,
        examVersion: exam.version,
        flaggedQuestions: [],
        disabledSubjects: [],
        user: newUser,
    }, { forceInsert: true })

    await afterAuth()
}

const resetPassword = async () => {
    // Need to ensure Parse.User.current() is up to date
    await refreshParseUser()
    const user = Parse.User.current<Study.Class.User>()
    if (!user) {
        throw new Error('resetPassword: No current user found.')
    }
    await Parse.User.requestPasswordReset(user.get('username'))
    return
}

const signIn = async ({
    username,
    password,
}: { username: string; password: string }) => {
    const user = (await Parse.User.logIn<Study.Class.User>(username.toLowerCase(), password)).toJSON()

    userModule.state.user = user
    analyticsModule.actions.updateIntercom()

    await afterAuth()

    await fetchUserData()

    return user
}

const signInWithCode = async ({
    email,
    code,
}: { email: string; code: string }) => {
    const { sessionToken, redirect } = await runCloudFunction<Study.Cloud.loginWithCode>('loginWithCode', {
        email,
        code,
    })

    const parseUser = await Parse.User.become<Study.Class.User>(sessionToken)
    const user = parseUser.toJSON()

    userModule.state.user = user
    analyticsModule.actions.updateIntercom()

    await afterAuth()

    await fetchUserData()

    return {
        user,
        redirect,
    }
}

const signInWithCookie = async (sessionToken: string) => {
    const parseUser = await Parse.User.become<Study.Class.User>(sessionToken)
    const user = parseUser.toJSON()

    userModule.state.user = user
    analyticsModule.actions.updateIntercom()

    await afterAuth()

    await fetchUserData()

    return user
}

const signOut = async () => {
    if (userModule.state.user || Parse.User.current()) {
        // if you have an invalid session token, logout throws and we don't care
        try {
            await Parse.User.logOut()
        } catch (err) {
            // noop
        }
        resetState()
        updateLocalSettings({ isDarkMode: false })

        Sentry.setUser(null)
        analyticsModule.actions.sprigLogoutUser()
        analyticsModule.actions.amplitudeReset()

        // Clear any referral info when user signs out
        localStorage.removeItem('referralInfo')

        if (import.meta.env.VUE_APP_INTERCOM_APP_ID && !isSupportWindow()) {
            window.Intercom('shutdown')
            analyticsModule.actions.bootIntercom()
        }
    }
}

const sendMagicEmail = async (
    { email, route }: 
    { email: string; route: RouteLocationNormalizedLoaded }
) => {
    let redirect: string | undefined
    let forceOpenWeb = false

    if (typeof route.query.license === 'string') {
        redirect = `/settings/exams?license=${route.query.license}`
    } else if (typeof route.query.referral === 'string') {
        redirect = `/study?referral=${route.query.referral}`
        forceOpenWeb = true
    } else if (typeof route.query.redirect === 'string') {
        redirect = route.query.redirect
    }

    await runCloudFunction<Study.Cloud.sendMagicEmail>('sendMagicEmail', {
        email: email.toLowerCase(),
        redirect,
        forceOpenWeb,
    })

    return redirect
}

const dismiss = async (dismissableId: string) => {
    userModule.state.dismissables[dismissableId] = true
}

const deleteUser = async () => {
    // Need to ensure Parse.User.current() is up to date
    await refreshParseUser()
    const user: Study.Class.User | undefined = Parse.User.current()

    if (!user) {
        throw new Error('No user found.')
    }

    user.set('deleteRequestedDate', new Date())
    await user.save()
    await signOut()
}

const cancelDeleteUser = async () => {
    // Need to ensure Parse.User.current() is up to date
    await refreshParseUser()
    const user: Study.Class.User | undefined = Parse.User.current()

    if (!user) {
        throw new Error('No user found.')
    }

    user.unset('deleteRequestedDate')
    await user.save()
    userModule.state.user = user.toJSON()
}

const updateQuizSettings = async (
    quizSettings: { [T in keyof Study.Class.UserQuizSettings]: Study.Class.UserQuizSettings[T] }
) => {
    // Need to ensure Parse.User.current() is up to date
    await refreshParseUser()
    const user: Study.Class.User | undefined = Parse.User.current()
    const stateUser = userModule.state.user

    if (!user || !stateUser) {
        throw new Error('No current user found.')
    }

    const updatedQuizSettings = {
        ...stateUser.quizSettings,
        ...quizSettings,
    }

    user.set('quizSettings', updatedQuizSettings)
    await user.save()
    
    userModule.state.user = user.toJSON()
}

const updateWebConfig = async (webConfig: { [T in keyof Study.Class.UserWebConfig]: Study.Class.UserWebConfig[T] }) => {
    if (userModule.state.updatingWebConfig) {
        return
    }

    userModule.state.updatingWebConfig = true

    // NOT calling refreshParseUser here, because webConfig is unlikely to have changed outside of current session
    const user: Study.Class.User | undefined = Parse.User.current()
    const stateUser = userModule.state.user

    if (!user || !stateUser) {
        throw new Error('No current user found.')
    }

    const updatedWebConfig = {
        ...stateUser.webConfig,
        ...webConfig,
    }

    const webConfigKeys = Object.keys(updatedWebConfig) as (keyof Study.Class.UserWebConfig)[]
    const hasWebConfigChanged = webConfigKeys.some(key =>
        updatedWebConfig[key] !== stateUser.webConfig?.[key]
    )
    if (!hasWebConfigChanged) {
        userModule.state.updatingWebConfig = false
        return
    }

    user.set('webConfig', updatedWebConfig)

    await user.save()
    
    userModule.state.user = user.toJSON()
    userModule.state.updatingWebConfig = false
}

const updateReminderPreferences = async (reminderFrequency: 'daily' | 'weekly' | 'never') => {
    // Need to ensure Parse.User.current() is up to date
    await refreshParseUser()
    const user: Study.Class.User | undefined = Parse.User.current()
    const stateUser = userModule.state.user

    if (!user || !stateUser) {
        throw new Error('No current user found.')
    }

    if (reminderFrequency === 'never') {
        user.unset('studyRemindersFrequency')
    } else {
        user.set({ 
            studyRemindersFrequency: reminderFrequency,
            studyRemindersEmail: stateUser.email,
        })
    }

    await user.save()
    userModule.state.user = user.toJSON()
}

// Ensures that Parse.User.current() is up to date
const refreshParseUser = async () => {
    const parseUser = Parse.User.current<Study.Class.User>()

    if (!parseUser) {
        throw new Error('Unable to find current user')
    }

    const currentUser = (await parseUser.fetch()).toJSON()
    userModule.state.user = currentUser

    return currentUser
}

// Actions run after registration or login and before anything else
const afterAuth = async () => {
    // update Sentry user context
    const user = userModule.state.user
    if (user) {
        Sentry.setUser({ 
            id: user.objectId,
            email: user.email,
        })
        analyticsModule.actions.setSprigUser({
            id: user.objectId,
            email: user.email,
        })
        analyticsModule.actions.setAmplitudeUser({
            id: user.objectId,
            email: user.email,
            createdAt: user.createdAt,
        })
    }

    // Handle saving of a shared QotD record if it is found
    const sharedQotDQuiz = qotdModule.state.sharedQotDQuizDraft
    if (sharedQotDQuiz?.answers.length) {
        await qotdModule.actions.fetchCurrentQotDQuestion()
        const qotdQuestion = qotdModule.getters.getQotDQuestion()
        // Only store SharedQotD if its for todays question and user hasnt answered todays question yet
        if (sharedQotDQuiz.answers[0] && qotdQuestion?.serial === sharedQotDQuiz.answers[0]?.questionSerial) {
            qotdModule.actions.recordQotD(sharedQotDQuiz.answers[0])
        }
        qotdModule.actions.clearSharedQotDDraft()
    }
}

const onboardingCompleted = async (params: {
    firstExamGuid: string
    firstBundleId: string
    signedUpOn: 'Web'
    onboardingResult: 'Free' | 'Monthly' | 'Quarterly' | 'Yearly' | 'License' | 'Legacy'
}) => {
    await runCloudFunction<Study.Cloud.onboardingCompleted>('onboardingCompleted', params)

    // Need to ensure Parse.User.current() is up to date
    await refreshParseUser()

    return
}

const incrementLevelUpOpenedCount = async () => {
    const currentWebConfig = userModule.state.user?.webConfig
    const levelUpOpenedCount = currentWebConfig?.levelUpOpenedCount
    await updateWebConfig({
        levelUpOpenedCount: (levelUpOpenedCount || 0) + 1,
    })
}

export default {
    dismiss,
    resetPassword,
    sendMagicEmail,
    signIn,
    signInWithCode,
    signInWithCookie,
    signOut,
    createUser,
    updateWebConfig,
    updateUser,
    refreshParseUser,
    forgotPassword,
    toggleIsDarkMode,
    updateCurrentExam,
    fetchUserData,
    updateQuizSettings,
    updateReminderPreferences,
    updateLocalSettings,
    deleteUser,
    cancelDeleteUser,
    onboardingCompleted,
    incrementLevelUpOpenedCount,
}

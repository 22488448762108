<script setup lang="ts">
import { userModule } from '@/store/user/module'
import { computed } from 'vue'
import UIKit from '@pocketprep/ui-kit'
const {
    ModalContainer,
    Button: PocketButton,
} = UIKit

defineProps<{
    newMinorContentCount: number
    examName: string | undefined
    typeOfMinorUpdate: 'newQuestions' | 'newMockExams'
}>()

const emit = defineEmits<{
    close: []
}>()

const isDarkMode = computed(() => {
    return userModule.state.settings.isDarkMode
})
</script>

<template>
    <Portal to="modal">
        <ModalContainer
            key="NewMinorExamVersionModal"
            :show-close-button="true"
            :is-dark-mode="isDarkMode"
            @close="emit('close')"
        >
            <div class="new-minor-exam-version-modal">
                <div v-if="typeOfMinorUpdate === 'newMockExams'" class="new-minor-exam-version-modal__title">
                    {{ newMinorContentCount }} {{ newMinorContentCount > 1 ? 'New Mock Exams' : 'New Mock Exam' }}
                </div>
                <div v-else class="new-minor-exam-version-modal__title">
                    {{ newMinorContentCount }} New Questions
                </div>
                <div v-if="typeOfMinorUpdate === 'newMockExams'" class="new-minor-exam-version-modal__content">
                    Our team is constantly looking for ways to improve your test prep experience. 
                    We have added {{newMinorContentCount === 1 ? 'a' : ''}} 
                    mock exam{{newMinorContentCount === 1 ? '' : 's'}} to give you 
                    more {{ examName }} test prep opportunities. 💪
                </div>
                <div v-else class="new-minor-exam-version-modal__content">
                    We have added questions to our {{ examName }} exam content. 
                    You may notice an impact to your stats. 
                </div>
                <PocketButton
                    class="new-minor-exam-version-modal__close-btn"
                    :isDarkMode="isDarkMode"
                    @click="emit('close')"
                >
                    Close
                </PocketButton>
            </div>
        </ModalContainer>
    </Portal>
</template>

<style lang="scss" scoped>
.new-minor-exam-version-modal {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0.3px;
        text-align: center;
        margin-top: 36px;
        margin-bottom: 12px;
    }

    &__content {
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        width: 298px;
        margin: 0 38px 32px 38px;
    }

    &__close-btn {
        margin-bottom: 24px;
        margin-left: 6px;
    }
}
</style>

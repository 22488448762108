import { subscriptionModule } from '@/store/subscription/module'
import { bundleModule } from '@/store/bundle/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { stripeModule } from '@/store/stripe/module'
import type { Study } from '@pocketprep/types'
import { planTypeLength } from '@/utils'

const getSubscriptionForExamId = (examId?: string) => {
    const subscriptions = subscriptionModule.state.subscriptions
    const currentExam = examMetadataModule.getters.getCurrentExamMetadata()
    const examMetadataById = examMetadataModule.getters.getExamMetadataById()
    const bundles = bundleModule.getters.getBundles()

    const exam = examId 
        ? examMetadataById[examId]
        : currentExam && examMetadataById[currentExam.objectId]
    if (!exam) {
        return false
    }

    if (exam.isFree) {
        return true
    }

    return subscriptions
        .find(s => {
            if (new Date(s.expiresAt.iso).getTime() < new Date().getTime()) {
                return false
            }

            if ('bundle' in s) {
                const bundle = bundles.find(b => b.objectId === s.bundle.objectId)
                return !!bundle?.exams.find(be => {
                    const bundleExam = examMetadataById[be.objectId]
                    return bundleExam?.examGuid === exam.examGuid
                })
            } else {
                return s.examGuid === exam.examGuid
            }
        }) || false
}

const premiumExamIds = () => {
    const subscriptions = subscriptionModule.state.subscriptions
    const examMetadata = examMetadataModule.getters.getExamMetadata()
    const premiumIndivExamIds = subscriptions
        .map(s => 'examGuid' in s && examMetadata.filter(e => e.examGuid === s.examGuid).map(e => e.objectId))
        .filter(s => s)
    const bundles = bundleModule.getters.getBundles()
    const premiumBundles = bundles.filter(b => premiumBundleIds().includes(b.objectId))

    return [
        ...premiumBundles.map(b => b.exams.map(e => e.objectId)).flat(),
        ...premiumIndivExamIds.flat(),
    ]
}

const premiumBundleIds = () => {
    const subscriptions = subscriptionModule.state.subscriptions

    return subscriptions
        .filter(s => new Date(s.expiresAt.iso).getTime() > new Date().getTime())
        .map(s => 'bundle' in s && s.bundle.objectId)
        .filter(id => !!id)
}

const getStripeSubscriptionLength = (subscription: boolean | Study.Class.SubscriptionJSON) => {
    let selectedPlan: 'Free' | 'Monthly' | 'Quarterly' | 'Yearly' = 'Free'

    if (subscription && typeof subscription === 'object') {
        const stripeSub = stripeModule.getters.getStripeSubscriptions().find(ss => ss.id === subscription.guid)
        if (stripeSub && stripeSub?.items.data[0].plan) {
            const plan = stripeSub?.items.data[0].plan
            selectedPlan = plan.interval_count === 1 && plan.interval === 'month' ? 'Monthly'
                : plan.interval_count === 3 ? 'Quarterly'
                    : 'Yearly'
        }
    }

    return selectedPlan
}

const getSubscriptionPlanLengthByExamId = (examId?: string) => {
    const subscriptions = subscriptionModule.state.subscriptions
    const currentExam = examMetadataModule.getters.getCurrentExamMetadata()
    const examMetadataById = examMetadataModule.getters.getExamMetadataById()
    const bundles = bundleModule.getters.getBundles()

    const exam = examId 
        ? examMetadataById[examId]
        : currentExam && examMetadataById[currentExam.objectId]
    if (!exam) {
        return undefined
    }

    let isBundleSub: boolean = true
    const sub = subscriptions
        .find(s => {
            if ('bundle' in s) {
                const bundle = bundles.find(b => b.objectId === s.bundle.objectId)
                isBundleSub = true
                return !!bundle?.exams.find(be => {
                    const bundleExam = examMetadataById[be.objectId]
                    return bundleExam?.examGuid === exam.examGuid
                })

            } else {
                isBundleSub = false
                return s.examGuid === exam.examGuid
            }
        }) || undefined

    let selectedPlanType: 'Free' | 'Monthly' | 'Quarterly' | 'Yearly' | 'License' | 'Legacy' = 'Free'
    if (sub?.source === 'Stripe') {
        selectedPlanType = getStripeSubscriptionLength(sub)
    } else if (sub?.source === 'Apple' || sub?.source === 'Google') {
        if (sub.days) {
            selectedPlanType = planTypeLength(sub.days)
        } else {
            const createdAtDate = new Date(sub.createdAt)
            const expiredAtDate = new Date(sub.expiresAt.iso)
            const timeDifference = expiredAtDate.getTime() - createdAtDate.getTime()
            const daysDifference = Math.round(timeDifference / (1000 * 60 * 60 * 24))
            selectedPlanType = planTypeLength(daysDifference)

        }
    } else if (!isBundleSub && sub?.source !== 'Teach' && sub?.source !== 'Support') {
        selectedPlanType = 'Legacy'
    } else if (sub?.source === 'Teach') {
        selectedPlanType = 'License'
    }

    return selectedPlanType
}

export default {
    getSubscriptionForExamId,
    premiumBundleIds,
    premiumExamIds,
    getStripeSubscriptionLength,
    getSubscriptionPlanLengthByExamId,
}
<template>
    <div v-dark class="quiz-scores">
        <div v-dark class="quiz-scores__head">
            <div v-dark class="quiz-scores__title">
                <Icon type="barChart" />
                <h2>Quiz Scores</h2>
            </div>
        </div>
        <div class="quiz-scores__stats">
            <div
                v-dark
                class="quiz-scores__stat"
                :aria-label="`${ orderedQuizzes.length } Quizzes`"
            >
                <div
                    v-dark
                    class="quiz-scores__stat-number"
                    aria-hidden="true"
                >
                    {{ orderedQuizzes.length }}
                </div>
                <div
                    v-dark
                    class="quiz-scores__stat-label"
                    aria-hidden="true"
                >
                    Quizzes
                </div>
            </div>
            <div
                v-dark
                class="quiz-scores__stat"
                :aria-label="`${ numDaysStudying } Day${ numDaysStudying !== 1 ? 's': '' } Studying`"
            >
                <div
                    v-dark
                    class="quiz-scores__stat-number"
                    aria-hidden="true"
                >
                    {{ numDaysStudying }}
                </div>
                <div
                    v-dark
                    class="quiz-scores__stat-label"
                    aria-hidden="true"
                >
                    Day{{ numDaysStudying !== 1 ? 's': '' }} Studying
                </div>
            </div>
        </div>
        <template v-if="!isShowingTextDescription">
            <div
                tabindex="-1"
                class="quiz-scores__chart-container"
                role="region"
                aria-label="Quiz scores graph, summary text description below"
            >
                <Bar
                    :key="`${quizScores.length}_${isDarkMode}`"
                    type="percent"
                    class="quiz-scores__bar"
                    size="small"
                    :bar-color="barColor"
                    :is-dark-mode="isDarkMode"
                    :hide-average-line="true"
                    :data="quizScores"
                    description="Graph of quiz scores by quiz"
                />
                <div class="quiz-scores__bar-x-labels">
                    <div v-dark class="quiz-scores__bar-x-label">
                        {{ chartXLabels.left }}
                    </div>
                    <div v-dark class="quiz-scores__bar-x-label">
                        {{ chartXLabels.right }}
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="isShowingTextDescription && orderedQuizzes.length > 0">
            <div
                ref="quiz-scores__text-description"
                class="quiz-scores__text-description"
                tabindex="-1"
            >
                {{ textDescriptionDateRange }}, 
                {{ textDescriptionScoreSummary }}.
            </div>
        </template>
        <template v-else-if="isShowingTextDescription && !orderedQuizzes.length">
            <div class="quiz-scores__text-description">
                You haven't taken a quiz yet. Quiz scores will display here when you take a quiz.
            </div>
        </template>
        <div
            v-dark
            tabindex="0"
            class="quiz-scores__text-toggle"
            :class="{
                'quiz-scores__text-toggle--active': isShowingTextDescription,
            }"
            role="switch"
            aria-label="Text Description"
            :aria-checked="isShowingTextDescription ? 'true' : 'false'"
            @focus="isShowingTextDescriptionTooltip = true"
            @blur="isShowingTextDescriptionTooltip = false"
            @mouseenter="isShowingTextDescriptionTooltip = true"
            @mouseleave="isShowingTextDescriptionTooltip = false"
            @click="toggleTextDescription"
            @keydown.enter="toggleTextDescription"
            @mousedown.prevent
        >
            <Tooltip
                v-if="isShowingTextDescriptionTooltip"
                :theme="breakpoint === 'black-bear' ? 'rightalign' : false"
                :is-dark-mode="isDarkMode"
            >
                Text Description
            </Tooltip>
            <Icon class="quiz-scores__text-toggle-icon" type="text" />
        </div>
    </div>
</template>

<script lang="ts">
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component } from 'vue-facing-decorator'
import BrandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'
import { userModule } from '@/store/user/module'
import { quizModule } from '@/store/quiz/module'
import { getScoreRange, months } from '@/utils'
import { screenModule } from '@/store/screen/module'

@Component({
    components: {
        Icon: UIKit.Icon,
        Bar: UIKit.Bar,
        Tooltip: UIKit.Tooltip,
    },
})
export default class QuizScores extends Vue {
    isShowingTextDescriptionTooltip = false
    isShowingTextDescription = false

    get orderedQuizzes () {
        return [ ...quizModule.state.quizzes ]
            .filter(q => (q.mode !== -1 && q.mode !== 5))
            .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
    }

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get quizScores () {
        if (!this.orderedQuizzes.length) {
            return [
                { y: 26 },
                { y: 31 },
                { y: 36 },
                { y: 41 },
                { y: 47 },
                { y: 51 },
                { y: 57 },
                { y: 67 },
                { y: 75 },
                { y: 82 },
                { y: 89 },
                { y: 97 },
            ]
        }

        return this.orderedQuizzes
            .reduce((acc, q) => {
                if (!q.answers.length) {
                    return acc
                }

                const dateObj = new Date(q.createdAt)
                const tooltipDate = this.formatTooltipDate(dateObj)
                const score = Math.round((q.correctCount / (q.correctCount + q.incorrectCount)) * 100)
                const hoverColor = this.getBarHoverColor(score)
                if (hoverColor) {
                    acc.push({ 
                        y: score,
                        states: {
                            hover: {
                                color: hoverColor,
                                borderColor: hoverColor,
                            },
                        },
                        info: tooltipDate,
                    })
                }

                return acc
            }, [] as { y: number; info: string; states: { hover: { color: string; borderColor: string } } }[])
    }

    get maxMinScoreInfo () {
        return this.orderedQuizzes.reduce((acc, q) => {
            const score = Math.round((q.correctCount / (q.correctCount + q.incorrectCount)) * 100)
            if (!acc.max || score > acc.max.score) {
                acc.max = {
                    score,
                    date: new Date(q.createdAt),
                }
            }
            if (!acc.min || score < acc.min.score) {
                acc.min = {
                    score,
                    date: new Date(q.createdAt),
                }
            }
            return acc
        }, { max: null, min: null } as Record<'max' | 'min', { score: number; date: Date } | null>)
    }

    get firstQuizDate () {
        return this.orderedQuizzes.length
            ? new Date(this.orderedQuizzes[0].createdAt)
            : null
    }

    get lastQuizDate () {
        return this.orderedQuizzes.length
            ? new Date(this.orderedQuizzes[this.orderedQuizzes.length - 1].createdAt)
            : null
    }

    get textDescriptionDateRange () {
        if (!this.firstQuizDate || !this.lastQuizDate) {
            return ''
        }

        if (this.numDaysStudying === 1) {
            return `On ${this.fullDate(this.firstQuizDate)}`
        }

        return `From ${this.fullDate(this.firstQuizDate)} through ${this.fullDate(this.lastQuizDate)}`
    }

    get textDescriptionScoreSummary () {
        if (!this.maxMinScoreInfo.max || !this.maxMinScoreInfo.min) {
            return ''
        }

        if (this.orderedQuizzes.length === 1) {
            return `your score was ${this.maxMinScoreInfo.max.score}%`
        }

        const uniqueScores = new Set(this.quizScores.map(s => s.y))
        if (uniqueScores.size === 1 || (this.numDaysStudying === 1)) {
            return `your highest score was ${
                this.maxMinScoreInfo.max.score
            }% and your lowest score was ${
                this.maxMinScoreInfo.min.score
            }%`
        }

        return `your highest score was ${
            this.maxMinScoreInfo.max.score
        }% on ${
            this.fullDate(this.maxMinScoreInfo.max.date)
        }, and your lowest score was ${
            this.maxMinScoreInfo.min.score
        }% on ${
            this.fullDate(this.maxMinScoreInfo.min.date)
        }`
    }

    get numDaysStudying () {
        if (!this.firstQuizDate || !this.lastQuizDate) {
            return 0
        }

        const startOfFirstQuizDate = new Date(
            this.firstQuizDate.getFullYear(),
            this.firstQuizDate.getMonth(),
            this.firstQuizDate.getDate()
        )

        const numDays = Math.ceil((this.lastQuizDate.getTime() - startOfFirstQuizDate.getTime()) / 86400000)
        return numDays
    }

    get shouldShowLabelYears () {
        const firstDateYear = this.firstQuizDate?.getFullYear()

        if (firstDateYear && firstDateYear !== new Date().getFullYear()) {
            return true
        } else {
            return false
        }
    }

    get chartXLabels () {
        const firstDate = this.firstQuizDate
        const lastDate = this.lastQuizDate

        if (!firstDate || !lastDate) {
            return {
                left: 'Oldest',
                right: 'Most Recent',
            }
        }
        
        return {
            left: `${months[firstDate.getMonth()]?.substr(0, 3)} ${firstDate.getDate()}${
                this.shouldShowLabelYears ? `, ${firstDate.getFullYear()}` : ''
            }`,
            right: `${months[lastDate.getMonth()]?.substr(0, 3)} ${lastDate.getDate()}${
                this.shouldShowLabelYears ? `, ${lastDate.getFullYear()}` : ''
            }`,
        }
    }

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get barColor () {
        return this.orderedQuizzes.length
            ? undefined
            : this.isDarkMode
                ? this.breakpoint === 'black-bear'
                    ? BrandColors.charcoal
                    : BrandColors.moonlitOcean
                : BrandColors.grayProgressBackground
    }

    mounted () {
        userModule.actions.fetchUserData()
    }

    getBarHoverColor (score: number) {
        const scoreRange = getScoreRange(score)
        return !this.isDarkMode
            ? scoreRange === 'low'
                ? BrandColors.tomato
                : scoreRange === 'medium'
                    ? BrandColors.cheddar
                    : BrandColors.field
            : scoreRange === 'low'
                ? BrandColors.brandRed
                : scoreRange === 'medium'
                    ? BrandColors.bananaBread
                    : BrandColors.jungleGreen
    }

    formatTooltipDate (date: Date) {
        const day = ('0' + date.getDate()).slice(-2)
        const month = ('0' + (date.getMonth() + 1)).slice(-2)
        const year = date.getFullYear()
        return `${month}.${day}.${year}`
    }

    fullDate (date: Date) {
        return `${
            months[date.getMonth()]
        } ${
            date.getDate()
        }, ${
            date.getFullYear()
        }`
    }

    toggleTextDescription () {
        this.isShowingTextDescription = !this.isShowingTextDescription

        if (this.isShowingTextDescription) {
            setTimeout(() => {
                const textDescriptionEl = this.$refs['quiz-scores__text-description'] as HTMLElement | undefined
                if (textDescriptionEl) {
                    textDescriptionEl.focus()
                }
            }, 0)
        }
    }
}
</script>

<style lang="scss" scoped>
.quiz-scores {
    background: $white;
    border-radius: 6px;
    padding: 3px 16px 10px;
    box-sizing: border-box;
    height: 410px;
    position: relative;

    @include breakpoint(brown-bear) {
        height: 430px;
    }

    @include breakpoint(black-bear) {
        height: 427px;
        padding: 4px 10px 0;
    }

    &--dark {
        background: rgba($white, 0.02);
        color: $fog;

        @include breakpoint(black-bear) {
            background: $brand-black;
        }
    }

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 38px;
        border-bottom: 1px solid $gray-divider;
        padding-left: 8px;
        margin-bottom: 14px;

        &--dark {
            border-color: $pewter;
        }
    }

    &__title {
        display: flex;
        align-items: center;

        svg {
            height: 22px;
            margin-right: 8px;
            color: $slate;
        }

        h2 {
            font-size: 16px;
            line-height: 22px;
            font-weight: 600;
            margin: 0;
        }

        &--dark svg {
            color: $pewter;
        }
    }

    &__stats {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    &__stat {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $pearl;
        border-radius: 6px;
        width: calc(50% - 6px);
        height: 65px;

        &--dark {
            background-color: $moonlit-ocean;

            @include breakpoint(black-bear) {
                background-color: $black-feather;
            }
        }

        @include breakpoint(brown-bear) {
            width: calc(50% - 8px);
        }

        @include breakpoint(black-bear) {
            width: calc(50% - 4px);
        }
    }

    &__stat-number {
        font-weight: 600;
        font-size: 26px;
        letter-spacing: 0.26px;
        line-height: 31px;
    }

    &__stat-label {
        font-size: 13px;
        line-height: 16px;
        color: $ash;

        &--dark {
            color: $fog;
        }
    }

    &__chart-container {
        outline: none;
    }

    &__bar {
        width: 100%;
        height: 196px;
        margin-top: 16px;

        @include breakpoint(brown-bear) {
            height: 202px;
        }
    }

    &__bar-x-labels {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 6px 45px 0 1px;
        margin-bottom: 11px;
        box-sizing: border-box;
    }

    &__bar-x-label {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.3px;
        color: $slate-01;

        &--dark {
            opacity: 0.8;
            color: $white;
        }
    }

    &__text-description {
        box-sizing: border-box;
        padding: 24px 7px;
        height: 244px;
        line-height: 26px;
        outline: none;
        font-weight: 400;
    }

    &__text-toggle {
        display: inline-flex;
        cursor: pointer;
        color: $brand-blue;
        border-radius: 6px;
        outline: none;
        position: absolute;
        left: 16px;
        bottom: 16px;

        &:hover {
            color: $brand-black;
        }

        &:focus {
            &::after {
                content: '';
                position: absolute;
                left: -2px;
                top: -2px;
                width: calc(100% + 4px);
                height: calc(100% + 4px);
                box-sizing: border-box;
                border: 1px solid $brand-blue;
                border-radius: 8px;
            }
        }

        @include breakpoint(black-bear) {
            left: 10px;
            bottom: 12px;
        }

        &--dark {
            color: $banana-bread;

            &:hover {
                color: $butterscotch;
            }

            &:focus {
                &::after {
                    border-color: $butterscotch;
                }
            }
        }

        &--active {
            background-color: $brand-blue;
            color: $white;

            &:hover {
                background-color: $brand-black;
                color: $white;
            }

            &:focus {
                &::after {
                    left: -3px;
                    top: -3px;
                    width: calc(100% + 6px);
                    height: calc(100% + 6px);
                    border-radius: 9px;
                }
            }

            &--dark {
                background-color: $banana-bread;
                color: $brand-black;

                &:hover {
                    background-color: $butterscotch;
                    color: $brand-black;
                }
            }
        }

        .uikit-tooltip {
            position: absolute;
            left: 50%;
            top: calc(-100% - 4px);

            @include breakpoint(brown-bear) {
                top: calc(-100% + 4px);
            }

            @include breakpoint(black-bear) {
                top: calc(-100% + 8px);
            }
        }
    }

    &__text-toggle-icon {
        @include breakpoint(brown-bear) {
            width: 36px;
            height: 36px;
        }

        @include breakpoint(black-bear) {
            width: 40px;
            height: 40px;
        }
    }
}
</style>

<script setup lang="ts">
import { bundleModule } from '@/store/bundle/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { toastModule } from '@/store/toast/module'
import { userModule } from '@/store/user/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { analyticsModule } from '@/store/analytics/module'
import { formatDate } from '@/utils'
import type { Study } from '@pocketprep/types'
import { computed, onMounted, ref, watch } from 'vue'
import DiscontinuedExamVersionModal from '@/components/Study/ExamVersionMessaging/DiscontinuedExamVersionModal.vue'
import NewMajorExamVersionModal from '@/components/Study/ExamVersionMessaging/NewMajorExamVersionModal.vue'
import NewMinorExamVersionModal from '@/components/Study/ExamVersionMessaging/NewMinorExamVersionModal.vue'
import SunsetExamVersionModal from '@/components/Study/ExamVersionMessaging/SunsetExamVersionModal.vue'
import ReviewNewVersionsSidePanel from '@/components/ReviewNewVersionsSidePanel.vue'

const isLoading = ref(true)
const messageToShow = ref<Study.Class.MessageName | null>(null)
const showReviewNewVersions = ref(false)
const isLoadingWasDiscontinued = ref(false)

const currentUser = computed(() => {
    return userModule.state.user
})

const userExamMetadata = computed(() => {
    return userExamMetadataModule.getters.getCurrentUserExamMetadata()
})

const currentExam = computed(() => {
    return examMetadataModule.getters.getCurrentExamMetadata()
})

const examBundle = computed(() => {
    return bundleModule.getters.getBundles().find(b => b.exams.find(e => e.objectId === currentExam.value?.objectId))
})

const currentOrganizationId = computed(() => {
    return userModule.getters.getCurrentOrganizationId() || undefined
})

const examVersions = computed(() => {
    if (!userExamMetadata.value || !userExamMetadata.value.examVersion) {
        return undefined
    }

    return examMetadataModule.getters.getExamMetadata()
        .filter(e =>
            e.examGuid === userExamMetadata.value?.examGuid 
            && (
                examBundle.value?.exams.find(be => e.objectId === be.objectId)
                || userExamMetadata.value.examVersion?.startsWith(`${e.version.split('.')[0]}.`)
            )
        )
        .sort((a, b) => 
            a.objectId === userExamMetadata.value?.objectId 
                ? 1 
                : -a.version.localeCompare(b.version, undefined, { numeric: true })
        )
})

const currentExamName = computed(() => {
    return currentExam.value ? currentExam.value.nativeAppName : ''
})

const currentExamVersion = computed(() => {
    const examMajorVersion = userExamMetadata.value?.examVersion?.split('.')[0]

    if (!examMajorVersion || !examVersions.value) {
        return undefined
    }

    return examVersions.value.find(e => e.version.split('.')[0] === examMajorVersion)
})

const newMajorExamVersion = computed(() => {
    if (examVersions.value && examVersions.value.length > 1) {
        const newestExam = examVersions.value[0]
        const examCurrentMajorVersion = newestExam.version.split('.')[0]
        const userCurrentMajorVersion = userExamMetadata.value?.examVersion?.split('.')[0] || '1'
        if (userCurrentMajorVersion < examCurrentMajorVersion) {
            return newestExam
        }
    }
    return null
})

const hasSeenMajorNewVersionMessage = computed(() => {
    const messagesSeen = userModule.state.user?.webConfig?.messagesSeen
    return messagesSeen?.some(message => 
        message.examMetadataId === newMajorExamVersion.value?.objectId && message.name === 'majorNewVersion'
    )
})

const currentExamSunsetIsoDate = computed(() => {
    return currentExam.value?.sunsetAt?.iso.slice(0, -1)
})

const monthBeforeSunsetDateTimestamp = computed(() => {
    if (currentExamSunsetIsoDate.value) {
        const sunsetDate = new Date(currentExamSunsetIsoDate.value)
        // sunsetDate - 1 month
        sunsetDate.setMonth(sunsetDate.getMonth() - 1)
        return sunsetDate.getTime()
    }
    return null
})

const monthAfterSunsetDateTimestamp = computed(() => {
    if (currentExamSunsetIsoDate.value) {
        const sunsetDate = new Date(currentExamSunsetIsoDate.value)
        // sunsetDate + 1 month
        sunsetDate.setMonth(sunsetDate.getMonth() + 1)
        return sunsetDate.getTime()
    }
    return null
})

const isCurrentExamVersionDiscontinued = computed(() => {
    // sunsetDate + 1 month
    const today = new Date()
    const todayTimestamp = today.getTime()
    return monthAfterSunsetDateTimestamp.value && todayTimestamp > monthAfterSunsetDateTimestamp.value ? true : false
})

const hasSeenExamVersionSunsetMessage = computed(() => {
    const messagesSeen = userModule.state.user?.webConfig?.messagesSeen
    return messagesSeen?.some(message => 
        message.examMetadataId === currentExam.value?.objectId && message.name === 'sunsetWillDiscontinue'
    )
})

const isWithinSunsetWarningTime = computed(() => {
    // If the exam is discontinued, we're outside the warning time range
    if (isCurrentExamVersionDiscontinued.value) {
        return false
    }

    // If there's no sunset date, then we're not within any warning time range
    const monthBeforeSunset = monthBeforeSunsetDateTimestamp.value
    if (!monthBeforeSunset) {
        return false
    }

    // If it is earlier than one month before the sunset date, we're outside the warning time range
    const today = new Date()
    const todayTimestamp = today.getTime()
    if (todayTimestamp < monthBeforeSunset) {
        return false
    }

    return true
})

const newMinorContentCount = computed(() => {
    return currentExam.value?.minorVersionMessaging?.count || 0
})

const typeOfMinorUpdate = computed(() => {
    return currentExam.value?.minorVersionMessaging?.type
})

const hasSeenMinorExamVersionMessage = computed(() => {
    const messagesSeen = userModule.state.user?.webConfig?.messagesSeen
    // Check if major and minor version matches that way we do not show this minor
    // exam version message when a patch is exported
    return messagesSeen?.some(message => 
        message.examMetadataId === currentExam.value?.objectId
        && message.version.split('.')[0] === currentExam.value?.version.split('.')[0]
        && message.version.split('.')[1] === currentExam.value?.version.split('.')[1]
        && (message.name === 'minorNewMockExams' || message.name === 'minorNewQuestions')
    )
})

const formattedDiscontinuedDate = computed(() => {
    if (currentExam.value) {
        const discontinuedDate = examMetadataModule.getters.getDiscontinuedDateForExam(currentExam.value)
        if (discontinuedDate) {
            return discontinuedDate.toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
            })
        }
    }
    return null
})

onMounted(async () => {
    if (currentUser.value) {
        await Promise.all([
            bundleModule.actions.fetchBundles(),
            examMetadataModule.actions.fetchExamMetadata(),
            userModule.actions.fetchUserData(),
            userExamMetadataModule.actions.fetchUserExamMetadata(),
        ])
    }
    isLoading.value = false
})

/**
 * If there is a high priority message seen, mark the lower priority messages as having been seen.
 * The high priority message is passed here so that all the messages can be saved together
 */
const markLowerPriorityMessagesSeen = async (
    highestPriorityMessage: NonNullable<Study.Class.UserWebConfig['messagesSeen']>[number]
) => {
    const messagesSeen = userModule.state.user?.webConfig?.messagesSeen
    const newMessagesSeen = messagesSeen ? [ ...messagesSeen ] : []
    newMessagesSeen.push(highestPriorityMessage)

    const nextMajor = newMajorExamVersion.value

    if (nextMajor && highestPriorityMessage.name === 'sunsetWillDiscontinue') {
        newMessagesSeen.push({
            name: 'majorNewVersion',
            examMetadataId: nextMajor.objectId,
            version: nextMajor.version,
            timestamp: Date.now(),
        })
    }

    const minorMessageTypeForNextMajor = newMajorExamVersion.value?.minorVersionMessaging?.type
    if (nextMajor && minorMessageTypeForNextMajor && [
        'sunsetWillDiscontinue',
        'sunsetWasDiscontinued',
        'majorNewVersion',
    ].includes(highestPriorityMessage.name)) {
        newMessagesSeen.push({
            name: minorMessageTypeForNextMajor === 'newMockExams'
                ? 'minorNewMockExams'
                : 'minorNewQuestions',
            examMetadataId: nextMajor.objectId,
            version: nextMajor.version,
            timestamp: Date.now(),
        })
    }
    await userModule.actions.updateWebConfig({
        messagesSeen: newMessagesSeen,
    })
}

const closeNewMajorExamVersionModal = async () => {
    if (newMajorExamVersion.value) {
        const seenMajorMessage = {
            name: 'majorNewVersion' as Study.Class.MessageName,
            examMetadataId: newMajorExamVersion.value.objectId,
            version: newMajorExamVersion.value.version,
            timestamp: new Date().getTime(),
        }
        majorVersionTrackAmplitudeEvent()
        await markLowerPriorityMessagesSeen(seenMajorMessage)
    }

    messageToShow.value = null
}

const reviewExamVersionsClicked = async () => {
    // Still mark the message(s) as having been seen and close the modal
    if (messageToShow.value === 'sunsetWillDiscontinue') {
        await closeSunsetModal()
        showReviewNewVersions.value = true
    } else if (messageToShow.value ===  'majorNewVersion') {
        await closeNewMajorExamVersionModal()
        showReviewNewVersions.value = true
    }
}

const closeSunsetModal = async () => {
    // User closes sunset modal or decides to review exam versions
    // Update webConfig.messagesSeen to have sunsetWillDiscontinue message
    if (currentExam.value) {
        const seenSunsetMessage = {
            name: 'sunsetWillDiscontinue' as Study.Class.MessageName,
            examMetadataId: currentExam.value.objectId,
            version: currentExam.value.version,
            timestamp: new Date().getTime(),
        }
        sunsetTrackAmplitudeEvent('sunsetWillDiscontinue')
        await markLowerPriorityMessagesSeen(seenSunsetMessage)
    }

    messageToShow.value = null
}

// Switching Exam Versions from Discontinued modal
const switchExamVersion = async () => {
    if (!examVersions.value?.[0]?.objectId) {
        messageToShow.value = null
        return
    }
    if (!userExamMetadata.value) {
        messageToShow.value = null
        return
    }
    if (!currentExam.value) {
        messageToShow.value = null
        return
    }
    if (!newMajorExamVersion.value) {
        messageToShow.value = null
        return
    }

    // Keep the discontinue modal open while switching from the discontinued version
    isLoadingWasDiscontinued.value = true

    sunsetTrackAmplitudeEvent('sunsetWasDiscontinued')

    // Mark discontinued message and any future minor message as seen
    const seenSunsetMessage = {
        name: 'sunsetWasDiscontinued' as Study.Class.MessageName,
        examMetadataId: currentExam.value.objectId,
        version: currentExam.value.version,
        timestamp: new Date().getTime(),
    }
    await markLowerPriorityMessagesSeen(seenSunsetMessage)

    await userModule.actions.updateCurrentExam(examVersions.value[0].objectId)

    isLoadingWasDiscontinued.value = false
    messageToShow.value = null

    toastModule.actions.displayToast({
        title: 'Exam version changed.',
    })
}

const closeMinorVersionModal = async () => {
    // Update webConfig.messagesSeen to have minor version type message
    if (currentExam.value && typeOfMinorUpdate.value) {
        const messagesSeen = userModule.state.user?.webConfig?.messagesSeen
        const newMessagesSeen = messagesSeen ? [ ...messagesSeen ] : []
        newMessagesSeen.push({
            name: typeOfMinorUpdate.value === 'newQuestions' ? 
                'minorNewQuestions' as Study.Class.MessageName :
                'minorNewMockExams' as Study.Class.MessageName,
            examMetadataId: currentExam.value.objectId,
            version: currentExam.value.version,
            timestamp: new Date().getTime(),
        })
        await userModule.actions.updateWebConfig({
            messagesSeen: newMessagesSeen,
        })
    }

    messageToShow.value = null
}

const sunsetTrackAmplitudeEvent = (sunsetMessageType: Study.Class.MessageName) => {
    if (sunsetMessageType === 'sunsetWasDiscontinued' || sunsetMessageType === 'sunsetWillDiscontinue') {
        const sunsetAmplitudeEventProperties: Record<string, string | undefined> = {
            bundleId: examBundle.value?.objectId,
            client: 'Web Study',
            clientAppId: 'study.pocketprep.com',
            clientVersion: currentExamVersion.value?.version,
            examGuid: currentExam.value?.examGuid,
            userDomain: userModule.getters.getUserDomain() || undefined,
            discontinueDateString: formattedDiscontinuedDate.value || undefined,
            examName: currentExam.value?.nativeAppName,
            sunsetDate: currentExam.value?.sunsetAt?.iso,
            sunsetMessagingType: sunsetMessageType === 'sunsetWasDiscontinued' ? 
                'wasDiscontinued' : 'willDiscontinue',
        }

        if (Object.values(sunsetAmplitudeEventProperties).every(value => !!value)) {
            sunsetAmplitudeEventProperties['organizationId'] = currentOrganizationId.value
            analyticsModule.actions.amplitudeTrack(
                'Student Sunset Message Triggered', 
                sunsetAmplitudeEventProperties
            )
        }
    }
}

const majorVersionTrackAmplitudeEvent = () => {
    const majorVersionAmplitudeEventProperties: Record<string, string | undefined> = {
        bundleId: examBundle.value?.objectId,
        client: 'Web Study',
        clientAppId: 'study.pocketprep.com',
        clientVersion: currentExamVersion.value?.version,
        examGuid: currentExam.value?.examGuid,
        userDomain: userModule.getters.getUserDomain() || undefined,
        examName: currentExam.value?.nativeAppName,
    }

    if (Object.values(majorVersionAmplitudeEventProperties).every(value => !!value)) {
        majorVersionAmplitudeEventProperties['organizationId'] = currentOrganizationId.value
        analyticsModule.actions.amplitudeTrack(
            'Student Major Update Message Triggered', 
            majorVersionAmplitudeEventProperties
        )
    }
}

const checkExamVersionMessageToShow = () => {
    // Don't show any version message until after having seen the welcome series modal
    if (!userModule.state.user?.webConfig?.hasSeenWelcomeModal) {
        messageToShow.value = null
        return
    }

    // Keep the sunsetWasDiscontinued modal open while switching from the discontinued version
    if (isLoadingWasDiscontinued.value) {
        messageToShow.value = 'sunsetWasDiscontinued'
        return
    }

    // Don't show any message while switching exams
    if (examMetadataModule.state.isSwitchingExams) {
        messageToShow.value = null
        return
    }

    if (isCurrentExamVersionDiscontinued.value && newMajorExamVersion.value) {
        // If exam is discontinued, show the discontinued message
        messageToShow.value = 'sunsetWasDiscontinued'
    } else if (isWithinSunsetWarningTime.value && !hasSeenExamVersionSunsetMessage.value && newMajorExamVersion.value) {
        // If exam is being sunset, show the sunset warning message (if not seen yet)
        messageToShow.value = 'sunsetWillDiscontinue'
    } else if (newMajorExamVersion.value && !hasSeenMajorNewVersionMessage.value) {
        // If there's a new major, show the major version message (if not seen yet)
        messageToShow.value = 'majorNewVersion'
    } else if (typeOfMinorUpdate.value && !hasSeenMinorExamVersionMessage.value) {
        // If there are new mock exams or new questions, show the minor version message (if not seen yet)
        messageToShow.value = typeOfMinorUpdate.value === 'newMockExams'
            ? 'minorNewMockExams'
            : typeOfMinorUpdate.value === 'newQuestions'
                ? 'minorNewQuestions'
                : null
    } else {
        messageToShow.value = null
    }
}

watch(currentUser, () => {
    if (currentUser.value && userExamMetadata.value) {
        checkExamVersionMessageToShow()
    }
})

watch(() => examMetadataModule.state.isSwitchingExams, () => {
    if (currentUser.value && userExamMetadata.value && !examMetadataModule.state.isSwitchingExams) {
        checkExamVersionMessageToShow()
    }
})
</script>

<template>
    <div v-if="!isLoading" class="exam-version-messaging">
        <DiscontinuedExamVersionModal
            v-if="messageToShow === 'sunsetWasDiscontinued'"
            :exam-name="currentExamName"
            :discontinuedDate="formattedDiscontinuedDate"
            @switchExamVersion="switchExamVersion"
        />
        <SunsetExamVersionModal
            v-else-if="messageToShow === 'sunsetWillDiscontinue'"
            :exam-name="currentExamName"
            :discontinuedDate="formattedDiscontinuedDate"
            @close="closeSunsetModal"
            @reviewExamVersions="reviewExamVersionsClicked"
        />
        <NewMajorExamVersionModal
            v-else-if="messageToShow === 'majorNewVersion'"
            :exam-name="currentExamName"
            :current-exam="currentExam"
            @close="closeNewMajorExamVersionModal"
            @reviewExamVersions="reviewExamVersionsClicked"
        />
        <NewMinorExamVersionModal
            v-else-if="typeOfMinorUpdate
                && (messageToShow === 'minorNewMockExams' 
                    || (messageToShow === 'minorNewQuestions' && newMinorContentCount > 1))
            "
            :new-minor-content-count="newMinorContentCount"
            :exam-name="currentExamName"
            :type-of-minor-update="typeOfMinorUpdate"
            @close="closeMinorVersionModal"
        />
        <ReviewNewVersionsSidePanel
            v-if="showReviewNewVersions && currentExamVersion"
            key="study"
            :passed-exam-versions="examVersions"
            :current-exam-version="currentExamVersion"
            :uem="userExamMetadata"
            :examMetadata="currentExam"
            @close="showReviewNewVersions = false"
        />
    </div>
</template>

<style lang="scss" scoped>
// .exam-version-messaging {
// }
</style>

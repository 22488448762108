<template>
    <div v-dark class="quiz-result-header">
        <Icon
            v-dark
            class="quiz-result-header__close-icon"
            type="close"
            title="Close"
            @click="emitClose"
        />
        <template v-if="mode">
            <Icon
                v-dark
                class="quiz-result-header__mode-icon"
                :type="mode.icon"
                :style="{
                    color: isDarkMode ? mode.iconColorDM : BrandColors.ash
                }"
            />
            <h1
                v-dark
                class="quiz-result-header__mode-name"
                tabindex="-1"
            >
                <template v-if="isMockExam">
                    Mock Exam Results
                </template>
                <template v-else-if="prebuiltQuiz">
                    {{ mode.name }} {{ 'key' in prebuiltQuiz && prebuiltQuiz.key || '' }}
                </template>
                <template v-else>
                    {{ mode.name }} Quiz Results
                </template>
            </h1>
        </template>
        <div class="quiz-result-header__quiz-info">
            <div
                v-if="dateTimeString"
                v-dark
                class="quiz-result-header__mode-bullet"
            >
                &bull;
            </div>
            <div
                v-if="dateTimeString"
                v-dark
                class="quiz-result-header__datetime"
            >
                {{ dateTimeString }}
            </div>
            <div
                v-if="levelUpQuizLevel && numOfLevelsInSubject"
                v-dark
                class="quiz-result-header__level-up-info"
            >
                <div
                    v-dark
                    class="quiz-result-header__mode-bullet"
                    :class="{
                        'quiz-result-header__mode-bullet--level-up': levelUpQuizLevel && numOfLevelsInSubject,
                    }" 
                >
                    &bull;
                </div>
                <div
                    class="quiz-result-header__level-up-quiz-info"
                    :class="{
                        'quiz-result-header__level-up-quiz-info--level-dark-mode': isDarkMode,
                    }" 
                >
                    Level {{ levelUpQuizLevel }}/{{ numOfLevelsInSubject }}
                </div>
            </div>
        </div>
        <div class="quiz-result-header__actions">
            <template v-if="mode && mode.shortName === 'levelUp'">
                <PocketButton
                    class="quiz-result-header__secondary-btn"
                    type="secondary"
                    :is-dark-mode="isDarkMode"
                    @click="emitClose"
                >
                    Close Results
                </PocketButton>
                <PocketButton
                    type="primary"
                    :is-dark-mode="isDarkMode"
                    @click="emitGoToLevelUp"
                >
                    Keep Studying
                </PocketButton>
            </template>
            <template v-else>
                <PocketButton
                    v-if="quiz && canRetakeQuiz && !isMockExam"
                    class="quiz-result-header__secondary-btn"
                    type="secondary"
                    :is-dark-mode="isDarkMode"
                    @click="emitRetake"
                >
                    Retake Quiz
                </PocketButton>
                <PocketButton
                    type="primary"
                    :is-dark-mode="isDarkMode"
                    @click="emitClose"
                >
                    Close Results
                </PocketButton>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import { userModule } from '@/store/user/module'
import { months } from '@/utils'
import type { Study } from '@pocketprep/types'
import UIKit, { utils } from '@pocketprep/ui-kit'
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'
import BrandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'
import { quizModule } from '@/store/quiz/module'

@Component({
    components: {
        Icon: UIKit.Icon,
        PocketButton: UIKit.Button,
    },
})
export default class QuizResultHeader extends Vue {
    @Prop() canRetakeQuiz!: boolean
    @Prop({ default: null }) quiz!: Study.Class.QuizJSON | null
    @Prop({ default: undefined }) levelUpQuizLevel!: number | undefined
    @Prop({ default: undefined }) numOfLevelsInSubject!: number | undefined

    BrandColors = BrandColors

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get mode () {
        if (!this.quiz) {
            return null
        }
        const code = String(this.quiz.mode)
        return utils.studyModes[(code === '1' ? '0' : code) as keyof typeof utils.studyModes]
    }

    get isMockExam () {
        return this.quiz?.mode === 5
    }

    get createdAt () {
        if (!this.quiz) {
            return null
        }
        return new Date(this.quiz.createdAt)
    }

    get dateTimeString () {
        if (!this.createdAt) {
            return null
        }

        const dateString = `${months[this.createdAt.getMonth()]?.slice(0, 3)} ${this.createdAt.getDate()}`
        const hours = this.createdAt.getHours()
        const minutes = `0${this.createdAt.getMinutes()}`.slice(-2)
        const timeString = `${(hours % 12) || 12}:${minutes}${ hours >= 12 ? 'pm' : 'am' }`
        return `${dateString} at ${timeString}`
    }

    get prebuiltQuiz () {
        const prebuiltQuizzes = quizModule.getters.getPrebuiltQuizzes()
        const prebuiltQuizId = this.quiz?.prebuiltQuiz?.objectId

        return (prebuiltQuizId && prebuiltQuizzes?.[prebuiltQuizId]) || {}
    }

    @Emit('close')
    emitClose () {
        return true
    }

    @Emit('retake')
    emitRetake () {
        return true
    }

    @Emit('goToLevelUp')
    emitGoToLevelUp () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.quiz-result-header {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 64px;
    border-bottom: 1px solid $gray-divider;
    padding-left: 44px;
    padding-right: 40px;
    background-color: $white;
    z-index: 3;

    &--dark {
        background-color: $charcoal;
        border-bottom-color: $jet;

        @include breakpoint(black-bear) {
            background-color: $brand-black;
        }
    }

    @include breakpoint(brown-bear) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @include breakpoint(black-bear) {
        flex-direction: column;
        height: auto;
        border-bottom: none;
        padding: 25px 0 0 0;
    }

    &__close-icon {
        display: none;
        position: absolute;
        top: 20px;
        left: 15px;
        width: 30px;
        height: 30px;
        z-index: 4;
        color: $brand-blue;

        @include breakpoint(black-bear) {
            display: block;
        }

        &--dark {
            color: $banana-bread;

            &:hover {
                color: $butterscotch;
            }
        }
    }

    &__mode-icon {
        margin-right: 12px;

        @include breakpoint(black-bear) {
            margin-right: 0;
            margin-bottom: 17px;
        }
    }

    &__mode-name {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.18px;
        line-height: 22px;
        outline: none;

        &--dark {
            color: $white;
        }

        @include breakpoint(black-bear) {
            margin-right: 0;
            margin-bottom: 4px;
            font-size: 17px;
            letter-spacing: 0.23px;
            line-height: 20px;
        }
    }

    &__quiz-info {
        display: flex;
        align-items: center;

        @include breakpoint(black-bear) {
            align-items: flex-start;
        }
    }

    &__mode-bullet {
        font-size: 16px;
        color: $ash;
        margin: 0 12px;

        &--dark {
            color: $fog;
        }

        @include breakpoint(brown-bear) {
            margin: 0 8px;
        }

        @include breakpoint(black-bear) {
            display: none;
        }

        &--level-up {
            @include breakpoint(black-bear) {
                margin: 2px 8px;
                display: block;
            }
        }
    }

    &__level-up-info {
        display: flex;
        align-items: center;

        @include breakpoint(black-bear) {
            align-items: flex-start;
        }
    }

    &__datetime,
    &__level-up-quiz-info {
        color: $ash;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;

        @include breakpoint(black-bear) {
            height: 20px;
            letter-spacing: 0.32px;
            font-size: 15px;
            font-weight: 500;
            line-height: 20px;
            margin-bottom: 10px;
        }

        &--dark {
            color: $fog;
        }

        &--level-dark-mode {
            color: white
        }
    }

    &__actions {
        display: flex;
        height: 100%;
        align-items: center;
        margin-left: auto;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__secondary-btn {
        margin-right: 4px;
    }
}
</style>

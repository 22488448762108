<template>
    <div v-dark class="study-activity">
        <div v-dark class="study-activity__head">
            <div v-dark class="study-activity__title">
                <Icon type="activity" />
                <h2>Study Activity</h2>
            </div>
            <FilterDropdown
                v-model="filterOptions"
                class="study-activity__filter"
                :align="breakpoint === 'black-bear' ? 'right' : 'left'"
                size="small"
                :responsive-button="true"
                filterLabel="Filter"
                filterOptionsLabel="Filter by Quiz Mode"
                :radioEntries="[]"
                :checkboxEntries="filterOptions"
                :is-dark-mode="isDarkMode"
            />
        </div>
        <BadgeBanner
            v-if="showBadgeBanner && hasActiveSubscription"
            badgeName="Weekly 100"
            badgeDescription="Answer 100 questions this week"
            :badgeEarnedCurrentWeek="currentWeekAnswerCount >= 100"
            :badgeEarnedTotalCount="numWeeksWith100Answers"
            :badgeEarnedDate="latestWeekWith100Answers && new Date(latestWeekWith100Answers.startOfWeekMs)"
            :is-dark-mode="isDarkMode"
        />
        <div v-if="enabledFilters.length" class="study-activity__filters">
            <div v-dark class="study-activity__filters-label">
                Filters:
            </div>
            <Pill
                v-for="filter in enabledFilters"
                :key="filter.id"
                :show-close="true"
                :is-dark-mode="isDarkMode"
                @click="removeFilter(filter.id)"
            >
                {{ filter.label }}
            </Pill>
        </div>
        <div v-if="enabledFilters.length && !studyActivities.length" class="study-activity__no-activity">
            <div v-dark class="study-activity__no-activity-label">
                There isn’t any study activity that<br>matches your filter criteria.
            </div>
        </div>
        <div v-else-if="!studyActivities.length" class="study-activity__no-activity">
            <div v-dark class="study-activity__no-activity-label">
                You haven't taken any quizzes
            </div>
            <PocketButton
                :is-dark-mode="isDarkMode"
                :disabled="isLoadingQuick10"
                @click="startQuick10"
            >
                Take a Quick 10
            </PocketButton>
        </div>
        <div v-else class="study-activity__activity-list">
            <div
                v-for="activity in studyActivities.slice(currentPage * quizzesPerPage, pageLastIndex)"
                :key="activity.quizId"
                v-dark
                class="study-activity__activity"
                tabindex="0"
                :aria-label="`${activity.name} ${activity.date} ${activity.score} ${activity.isLocked ? 'locked' : ''}`"
                @click="activityClicked(activity)"
                @keydown.enter="activityClicked(activity)"
                @mousedown.prevent
            >
                <StudyActivityCard
                    class="study-activity__activity-card"
                    :activity="activity"
                />
            </div>
        </div>
        <div 
            v-if="studyActivities.length"
            class="study-activity__pagination"
        >
            <TablePagination
                class="study-activity__pagination-arrows" 
                :perPage="8" 
                unit="quizzes" 
                :currentPage="currentPage" 
                :total="studyActivities.length"
                :is-dark-mode="isDarkMode"
                @changePage="(val: number) => currentPage = val"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { quizModule } from '@/store/quiz/module'
import { userModule } from '@/store/user/module'
import { getScoreRange, months } from '@/utils'
import UIKit, { utils } from '@pocketprep/ui-kit'
import { Vue, Component, Watch } from 'vue-facing-decorator'
import { qotdModule } from '@/store/qotd/module'
import { subscriptionModule } from '@/store/subscription/module'
import { mockExamModule } from '@/store/mockExam/module'
import { screenModule } from '@/store/screen/module'
import { bundleModule } from '@/store/bundle/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import type { TFilterOption } from '@/utils'
import BadgeBanner from '@/components/Stats/BadgeBanner.vue'
import { analyticsModule } from '@/store/analytics/module'
import StudyActivityCard from './StudyActivityCard.vue'

type TStudyActivity = { 
    quizId: string
    isLocked: boolean
    icon: string
    name: string
    iconColor: string
    date: string
    score: string
    scoreRange: string
}
    
@Component({
    components: {
        Icon: UIKit.Icon,
        PocketLink: UIKit.Link,
        PocketButton: UIKit.Button,
        Checkbox: UIKit.Checkbox,
        Pill: UIKit.Pill,
        FilterDropdown: UIKit.FilterDropdown,
        TablePagination: UIKit.TablePagination,
        BadgeBanner,
        StudyActivityCard,
    },
})
export default class StudyActivity extends Vue {
    isLoading = true
    isLoadingQuick10 = false
    currentPage = 0
    quizzesPerPage = 8
    filterOptions: TFilterOption[] = [
        {
            id: 'qotd',
            label: 'Question of the Day',
            enabled: false,
        },
        {
            id: 'quick10',
            label: 'Quick 10',
            enabled: false,
        },
        {
            id: 'timed',
            label: 'Timed Quiz',
            enabled: false,
        },
        {
            id: 'missed',
            label: 'Missed Questions',
            enabled: false,
        },
        {
            id: 'weakest',
            label: 'Weakest Subject',
            enabled: false,
        },
        {
            id: 'custom',
            label: 'Build Your Own',
            enabled: false,
        },
    ]
    badgeExperimentVariantValue: 'badges-not-visible' | 'badges-visible' | null = null

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get pageLastIndex () {
        const lastIndex = (this.currentPage * this.quizzesPerPage) + this.quizzesPerPage

        return lastIndex > this.studyActivities.length
            ? this.studyActivities.length
            : lastIndex
    }

    get enabledFilters () {
        this.currentPage = 0
        return this.filterOptions.reduce((acc, option) => {
            if (!option.enabled) {
                return acc
            }

            acc.push(option)
            return acc
        }, [] as TFilterOption[])
    }

    get enableNextPage () {
        return ((this.currentPage * this.quizzesPerPage) + 1 + this.quizzesPerPage) <= this.studyActivities.length
    }

    get todaysQotD () {
        return qotdModule.getters.getQotDQuestion()
    }

    get areMockExamsAvailable () {
        return mockExamModule.getters.getCurrentMockExams().length > 0
    }

    get sortedQuizzes () {
        return [ ...quizModule.getters.getQuizzes() ]
            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    }

    get prebuiltQuizzes () {
        return quizModule.getters.getPrebuiltQuizzes()
    }
    
    get studyActivities () {
        const enabledFilterIds = this.enabledFilters.map(f => f.id)

        return this.sortedQuizzes.reduce<TStudyActivity[]>((acc, q) => {
            if (this.enabledFilters.length) {
                if (
                    (!enabledFilterIds.includes('qotd') && q.mode === -1)
                    || (!enabledFilterIds.includes('quick10') && q.mode === 0)
                    || (!enabledFilterIds.includes('missed') && q.mode === 2)
                    || (!enabledFilterIds.includes('weakest') && q.mode === 3)
                    || (!enabledFilterIds.includes('timed') && q.mode === 4)
                    || (!enabledFilterIds.includes('mockExam') && q.mode === 5)
                    || (!enabledFilterIds.includes('levelUp') && q.mode === 6)
                    || (!enabledFilterIds.includes('custom') && q.mode === 10)
                    || (!enabledFilterIds.includes('prebuiltQuiz') && q.mode === 11)){
                    return acc
                }
            }

            // if study mode isn't in library AND isn't 1 (aka legacy), remove from quizzes
            if (q.mode !== 1 && !utils.studyModes[q.mode as -1 | 0 | 2 | 3 | 4 | 10 | 11 | 5 | 6 ]) {
                return acc
            }

            const { icon, name, iconColor, iconColorDM } = 
                utils.studyModes[q.mode === 1 ? 0 : q.mode as -1 | 0 | 2 | 3 | 4 | 10 | 11 | 5 | 6 ]
            const date = new Date(q.createdAt)
            const score = Math.round((q.correctCount / (q.incorrectCount + q.correctCount)) * 100)
            const isLocked = !this.hasActiveSubscription && name === 'Question of the Day' 
                && this.todaysQotD?.serial !== q.answers[0]?.questionSerial
            const prebuiltQuiz = q.prebuiltQuiz && this.prebuiltQuizzes[q.prebuiltQuiz.objectId]
            const nameSuffix = prebuiltQuiz
                ? ` ${prebuiltQuiz.key}`
                : ''

            acc.push({
                quizId: q.objectId,
                icon,
                name: `${name}${nameSuffix}`,
                isLocked,
                iconColor: this.isDarkMode ? iconColorDM : iconColor,
                date: `${months[date.getMonth()]?.substr(0, 3)} ${date.getDate()}`,
                score: q.mode === -1
                    ? q.correctCount === 1
                        ? 'correct'
                        : 'incorrect'
                    : `${score}%`,
                scoreRange: getScoreRange(score),
            })

            return acc
        }, [])
    }

    get weeklyAnswerHistory () {
        return quizModule.getters.getWeeklyAnswerHistory()
    }

    get weeksWith100Answers () {
        return this.weeklyAnswerHistory.filter(weekHistory => weekHistory.answerCount >= 100)
    }

    get latestWeekWith100Answers () {
        const latestWeek = this.weeksWith100Answers.sort((a, b) => b.startOfWeekMs - a.startOfWeekMs)[0]
        return latestWeek
    }

    get numWeeksWith100Answers () {
        return this.weeksWith100Answers.length
    }

    get currentWeekAnswerCount () {
        return quizModule.getters.getCurrentWeekAnswerCount()
    }

    get hasActiveSubscription () {
        return !!subscriptionModule.getters.getSubscriptionForExamId()
    }

    get currentBundle () {
        return bundleModule.getters.getCurrentBundle()
    }

    get currentExam () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get showBadgeBanner () {
        return !this.isLoading && this.badgeExperimentVariantValue !== 'badges-not-visible'
    }

    async mounted () {
        await Promise.all([
            userModule.actions.fetchUserData(),
            qotdModule.actions.fetchCurrentQotDQuestion(),
            mockExamModule.actions.fetchMockExams(),
            bundleModule.actions.fetchBundles(),
            examMetadataModule.actions.fetchExamMetadata(),
            quizModule.actions.fetchPrebuiltQuizzes(),
        ])

        await this.fetchBadgeExperimentVariant()

        const levelUpIsAvailable = await this.isLevelUpEnabled()
        if (levelUpIsAvailable && !this.filterInOpts('levelUp')) {
            this.filterOptions.push({
                id: 'levelUp',
                label: 'Level Up',
                enabled: false,
            })
        }

        if (this.areMockExamsAvailable && !this.filterInOpts('mockExam')) {
            this.filterOptions.push({
                id: 'mockExam',
                label: 'Mock Exam',
                enabled: false,
            })
        }
        this.isLoading = false
    }

    removeFilter (filterId: string) {
        this.filterOptions = this.filterOptions.map(o => o.id === filterId ? { ...o, enabled: false } : o)
    }

    filterInOpts (filterId: string) {
        return this.filterOptions.some(opt => opt.id === filterId)
    }

    async fetchBadgeExperimentVariant () {
        // Fetch badge experiment variant if user is premium
        if (this.hasActiveSubscription) {
            const badgeExperimentVariant = await analyticsModule.actions.amplitudeFetchVariant('badges-experiment')
            const badgeExperimentVariantValue = badgeExperimentVariant?.value as 'badges-not-visible' | 'badges-visible'
            this.badgeExperimentVariantValue = badgeExperimentVariantValue || null
        }
    }

    async isLevelUpEnabled () {
        if (this.currentExam?.examGuid && this.currentBundle?.objectId) {
            return await quizModule.actions.isLevelUpEnabled({ 
                examGuid: this.currentExam.examGuid, 
                bundleId: this.currentBundle.objectId,
            })
        }
        
        return false
    }

    activityClicked (activity: TStudyActivity) {
        if (activity.name !== 'Question of the Day') {
            this.$router.push({
                name: 'quiz-result',
                params: {
                    quizId: activity.quizId,
                },
            })
        } else {
            this.$router.push({
                name: 'qotd',
                params: {
                    quizId: activity.quizId,
                },
            })
        }
    }

    async startQuick10 () {
        if (!this.isLoadingQuick10) {
            this.isLoadingQuick10 = true
            await quizModule.actions.createActiveQuiz({ mode: 'quick10', questionCount: 10 })
    
            this.$router.push({ name: 'quiz' })
            this.isLoadingQuick10 = false
        }
    }

    // there is not a way to look to see if an exam has changed
    // but we can see if there is a change in sorted quizzes
    @Watch('sortedQuizzes')
    async updateFilterOptions () {
        const levelUpIsAvailable = await this.isLevelUpEnabled()
        if (this.areMockExamsAvailable && !this.filterInOpts('mockExam')) {
            this.filterOptions.push({
                id: 'mockExam',
                label: 'Mock Exam',
                enabled: false,
            })
        } 
        if (!this.areMockExamsAvailable) {
            this.filterOptions = this.filterOptions.filter(opt => opt.id !== 'mockExam')
        }

        if (levelUpIsAvailable && !this.filterInOpts('levelUp')) {
            this.filterOptions.push({
                id: 'levelUp',
                label: 'Level Up',
                enabled: false,
            })
        } 
        if (!levelUpIsAvailable) {
            this.filterOptions = this.filterOptions.filter(opt => opt.id !== 'levelUp')
        }
    }

    @Watch('hasActiveSubscription')
    async hasActiveSubscriptionChanged () {
        // Don't fetch badge variant if component already loading
        if (!this.isLoading) {
            this.isLoading = true
            await this.fetchBadgeExperimentVariant()
            this.isLoading = false
        }
    }
}
</script>

<style lang="scss" scoped>
.study-activity {
    background: $white;
    border-radius: 6px;
    padding: 3px 16px 1px;
    box-sizing: border-box;
    min-height: 428px;
    display: flex;
    position: relative;
    flex-direction: column;

    @include breakpoint(black-bear) {
        padding: 4px 10px 0;
        min-height: 427px;
    }

    &--dark {
        color: $fog;
        background: rgba($white, 0.02);

        @include breakpoint(black-bear) {
            background: $brand-black;
        }
    }

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 38px;
        border-bottom: 1px solid $gray-divider;
        padding-left: 8px;

        &--dark {
            border-color: $pewter;
        }

        h2 {
            margin: 0;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
        }
    }

    &__title {
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        display: flex;
        align-items: center;

        svg {
            height: 26px;
            width: 21px;
            margin-right: 8px;
            color: $slate;
        }

        &--dark svg {
            color: $pewter;
        }
    }

    &__filter {
        margin-right: 11px;
    }

    &__no-activity {
        margin-top: 55px;
        text-align: center;
    }

    &__no-activity-label {
        font-size: 14px;
        line-height: 19px;
        color: $slate-03;
        margin-bottom: 24px;

        &--dark {
            color: $white;
        }
    }

    &__activity-list {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &__pagination {
        display: flex;
        justify-content: flex-end;
        height: 48px;
        align-items: center;
        outline: none;
    }

    &__pagination-arrows {
        display: flex;
        margin: 0 4px;

        :deep(.uikit-table-pagination__previous) {
            margin-right: 6px;
        }
    }

    &__filters {
        margin: 10px 0 6px;
    }

    &__filters-label {
        font-size: 13px;
        line-height: 22px;
        color: $slate-03;
        margin-right: 9px;
        display: inline-block;
        margin-left: 12px;
        vertical-align: top;

        @include breakpoint(black-bear) {
            margin-left: 0;
        }

        &--dark {
            color: $white;
        }
    }
}
</style>
import { initLoadable } from '@/store/utils'
import type { Loadable } from '@/store/utils'
import type { Study } from '@pocketprep/types'

export type TSubjectWithLevels = {
    subjectName: string
    subjectId: string
    levels: number[]
}

type TExamMetadataState = {
    examMetadata: Loadable<Study.Class.ExamMetadataJSON[]>
    subjectsWithLevels: TSubjectWithLevels[]
    isSwitchingExams: boolean
}

export const getInitialExamMetadataState = (): TExamMetadataState => {
    return {
        examMetadata: initLoadable([]),
        subjectsWithLevels: [],
        isSwitchingExams: false,
    }
}

const moduleState = getInitialExamMetadataState()

export default moduleState

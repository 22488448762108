<template>
    <div class="user-menu">
        <UpgradeSidePanel
            v-if="showUpgradeSidePanel"
            key="userMenu"
            :has-active-referral-link="validReferral"
            :referral-link-errors="[ referralError ]"
            @close="showUpgradeSidePanel = false"
        />
        <div
            ref="dropdownBtn"
            v-dark
            class="user-menu__button"
            role="button"
            :aria-expanded="showDropdown ? 'true' : 'false'"
            :aria-label="`${firstName} user dropdown`"
            tabindex="0"
            @click="showDropdown = !showDropdown"
            @keydown.enter="showDropdown = !showDropdown"
            @mousedown.prevent
        >
            <div aria-hidden="true" class="user-menu__initial">
                <span>{{ initial }}</span>
            </div>
            <div aria-hidden="true" class="user-menu__name">
                {{ firstName }}
            </div>
        </div>
        <UserMenuDropdown
            v-if="showDropdown"
            ref="dropdown"
            v-dark
            class="user-menu__dropdown"
            @close="showDropdown = false"
            @clickShowUpgrade="showUpgradeSidePanel = true; showDropdown = false"
            @clickDropdownOption="clickDropdownOption"
        />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import UserMenuDropdown from '@/components/Head/UserMenuDropdown.vue'
import UpgradeSidePanel from '@/components/Settings/UpgradeSidePanel.vue'
import { userModule } from '@/store/user/module'
import { referralModule } from '@/store/referral/module'
import { instructorModule } from '@/store/instructor/module'
import { getSessionToken } from '@/store/parseUtils'
import { setCookie } from '@/utils'
import type { ComponentPublicInstance } from 'vue'

@Component({
    components: {
        UserMenuDropdown,
        UpgradeSidePanel,
    },
})
export default class UserMenu extends Vue {
    showDropdown = false
    showUpgradeSidePanel = false
    isLoggingOut = false

    get initial () {
        return userModule.getters.userInitial()
    }

    get firstName () {
        return userModule.state.user?.firstName || userModule.state.user?.email.split('@')[0]
    }

    get validReferral () {
        return referralModule.getters.getValidReferral()
    }

    get referralError () {
        return referralModule.getters.getReferralError()
    }

    get lastActiveApp () {
        return userModule.state.user?.webConfig?.lastActiveApp
    }

    async mounted () {
        await userModule.actions.fetchUserData()
        if (this.lastActiveApp === 'teach') {
            const teachURL = import.meta.env.VUE_APP_TEACH_URL
            const sessionCookieName = import.meta.env.VUE_APP_SESSION_COOKIE_NAME
            const sessionToken = getSessionToken()
            if (teachURL && sessionToken && sessionCookieName) {
                setCookie({ name: sessionCookieName, value: sessionToken })
                window.location.href = teachURL
            }
        } else if (userModule.state.user && !userModule.state.updatingWebConfig) {
            await userModule.actions.updateWebConfig({
                lastActiveApp: 'study',
            })
        }
        
        document.addEventListener('click', this.clickListener)

        await Promise.all([
            referralModule.actions.validateReferral(),
            instructorModule.actions.fetchCurrentUserOrgInstructors(),
        ])
    }

    beforeUnmount () {
        document.removeEventListener('click', this.clickListener)
    }

    clickListener (e: MouseEvent) {
        if (this.showDropdown) {
            const targetEl = e.target as HTMLElement
            const dropdown = this.$refs.dropdown as ComponentPublicInstance | undefined
            const dropdownEl = dropdown?.$el as HTMLElement | undefined
            const dropdownBtnEl = this.$refs.dropdownBtn as HTMLElement | undefined

            if (
                !dropdownBtnEl?.contains(targetEl)
                && !dropdownEl?.contains(targetEl) 
                && document.body.contains(targetEl)
            ) {
                this.showDropdown = false
            }
        }
    }

    async clickDropdownOption (option: string) {
        if (option === 'signOut' && !this.isLoggingOut) {
            this.isLoggingOut = true
            this.showDropdown = false
            await userModule.actions.signOut()
            this.$router.push({ name: 'sign-in' })
            this.isLoggingOut = false
        }
    }
}
</script>

<style lang="scss" scoped>
.user-menu {
    position: relative;
    padding: 8px 0;
    z-index: 1001; // just above side panel

    &__button {
        display: flex;
        padding: 5px 8px 5px 6px;
        cursor: pointer;
        user-select: none;
        border-radius: 6px;
        border: 1px solid transparent;

        &:hover {
            background: $charcoal;
        }

        &:focus {
            background: $charcoal;
            border: 1px solid $banana-bread;
            outline: none;
        }

        &--dark {
            &:hover,
            &:focus {
                background: $brand-black;
            }
        }
    }

    &__initial {
        width: 26px;
        height: 26px;
        margin-right: 12px;
        background: linear-gradient(216.65deg, $beach-sand 0%, $cream-puff 100%);
        color: $flat-brown;
        font-size: 16px;
        font-weight: 600;
        line-height: 1px;
        border-radius: 26px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__name {
        font-size: 15px;
        line-height: 26px;
        text-transform: capitalize;
        max-width: 120px;
        text-overflow: ellipsis;
        overflow: hidden;

        @include breakpoint(grizzly-bear) {
            max-width: 90px;
        }
    }

    &__dropdown {
        position: absolute;
        top: 54px;
        left: -57px;
        background: $white;
        box-shadow: 0 6px 22px 0 rgba($brand-black, 0.2);
        width: 220px;
        border-radius: 0 0 8px 8px;

        &--dark {
            background: $charcoal;
            box-shadow: 0 6px 22px 0 rgba($jet, 0.6);
        }

        @include breakpoint(grizzly-bear) {
            left: -70px;
        }
    }
}
</style>
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { userModule } from '@/store/user/module'
import { isPromise } from '@/store/utils'
import type { TSubjectWithLevels } from '@/store/examMetadata/state'
import { difference } from '@/utils'

const getUserExamMetadata = () => {
    const userExamMetadata = userExamMetadataModule.state.userExamMetadata.value
    if (isPromise(userExamMetadata)) {
        return []
    } else {
        return userExamMetadata
    }
}

const getUserExamMetadataForObjectId = (objectId: string) => {
    return getUserExamMetadata().find(uem => uem.objectId === objectId)
}

const getUserExamMetadataForExamGuid = (examGuid: string) => {
    // If examGuid is user's current examGuid, return their currentUserExamMetadata
    const currentUEM = getCurrentUserExamMetadata()
    if (examGuid === currentUEM?.examGuid) {
        return currentUEM
    }

    return getUserExamMetadata().find(uem => uem.examGuid === examGuid)
}

const getCurrentUserExamMetadata = () => {
    const uems = userExamMetadataModule.getters.getUserExamMetadata()

    const currentUEMPointer = userModule.state.user?.currentUserExamMetadata
    const currentUEMFromPointer = uems.find(uem => uem.objectId === currentUEMPointer?.objectId) || null
    if (currentUEMFromPointer) {
        return currentUEMFromPointer
    }

    // Also still check currentExamGuid for now, for legacy UEM records
    const examGuid = userModule.state.user?.currentExamGuid
    const currentUEMFromExamGuid = examGuid && uems.find(uem => uem.examGuid === examGuid)

    return currentUEMFromExamGuid || null
}

const getFlaggedQuestions = () => {
    const uem = userExamMetadataModule.getters.getCurrentUserExamMetadata()
    return uem?.flaggedQuestions || []
}

const getLevelUpProgress = () => {
    const uem = userExamMetadataModule.getters.getCurrentUserExamMetadata()
    return uem?.levelUpProgress || []
}

const getMessageReferralConfig = () => {
    const uem = userExamMetadataModule.getters.getCurrentUserExamMetadata()
    return uem?.messageConfig?.referral
}

const getUemDisabledSubjects = (examGuid: string | undefined, subjects: TSubjectWithLevels[]) => {
    if (!examGuid || !subjects) {
        return []
    }

    const uemForExamGuid = userExamMetadataModule.getters.getUserExamMetadataForExamGuid(examGuid)
    const disabledSubjectIds = uemForExamGuid?.disabledSubjectIds
    const disabledSubjectNames = uemForExamGuid?.disabledSubjects

    // Start by checking disabledSubjectIds
    // There may be a mismatch between the number of disabledSubjectIds and disabledSubjectNames
    // Return disabledSubjectIds plus any extra disabledSubjects from disabledSubjectNames
    if (disabledSubjectIds?.length) {
        if (disabledSubjectNames?.length) {
            const disabledSubjectNameIds = subjects.reduce<string[]>((acc, subject) => {
                if (disabledSubjectNames?.includes(subject.subjectName)) {
                    acc.push(subject.subjectId)
                }
                return acc
            }, [])
            return [ ...disabledSubjectIds, ...difference(disabledSubjectNameIds, disabledSubjectIds) ]
        }
        return disabledSubjectIds
    }

    // If the user does not have disabledSubjectIds on their uem record,
    // then return the converted disabledSubjectIds array from the disabledSubjectNames
    if (disabledSubjectNames?.length) {
        return subjects.reduce<string[]>((acc, subject) => {
            if (disabledSubjectNames?.includes(subject.subjectName)) {
                acc.push(subject.subjectId)
            }
            return acc
        }, [])
    }

    return []
}

export default {
    getUserExamMetadata,
    getUserExamMetadataForObjectId,
    getUserExamMetadataForExamGuid,
    getCurrentUserExamMetadata,
    getFlaggedQuestions,
    getLevelUpProgress,
    getMessageReferralConfig,
    getUemDisabledSubjects,
}

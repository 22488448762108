<template>
    <UpgradeSidePanel
        v-if="showUpgradeSidePanel"
        key="levelUpQuizIntroUpgrade"
        :show-full-height="true"
        @close="showUpgradeSidePanel = false"
    />
    <LevelUpResetModal
        v-if="showResetModal"
        :isResetLoading="isResetLoading"
        @close="showResetModal = false"
        @reset="resetProgress"
    />
    <div v-dark class="level-up-quiz-intro">
        <Icon
            v-dark
            type="close"
            class="level-up-quiz-intro__close"
            title="Close"
            tabindex="0"
            @click="backToStudy"
            @keydown.enter="backToStudy"
            @mousedown.prevent
        />
        <BottomRightBlob class="level-up-quiz-intro__bottom-right-blob" />
        <TopLeftBlob class="level-up-quiz-intro__top-left-blob" />
        <div v-if="!isLoading" class="level-up-quiz-intro__content">
            <div v-dark class="level-up-quiz-intro__title">
                <Icon class="level-up-quiz-intro__title-icon" type="levelup" />
                Level Up
            </div>
            <template v-if="step === 'whatToExpect'">
                <div v-dark class="level-up-quiz-intro__what-to-expect">
                    <div class="level-up-quiz-intro__before-started">
                        Before you get started
                    </div>
                    <div class="level-up-quiz-intro__before-started-text">
                        Level Up is a quiz-based game where your question bank is
                        divided into levels based on difficulty.
                    </div>
                    <div class="level-up-quiz-intro__what-to-expect-title">
                        What to Expect
                    </div>
                    <ol class="level-up-quiz-intro__what-to-expect-list">
                        <li class="level-up-quiz-intro__what-to-expect-list-item">
                            Level Up questions are from your main bank - they are not new questions.
                        </li>
                        <li class="level-up-quiz-intro__what-to-expect-list-item">
                            You will always see answers as you go.
                        </li>
                        <li class="level-up-quiz-intro__what-to-expect-list-item">
                            Once you have correctly answered 85% of the questions
                            in a level, you will unlock the next level.
                        </li>
                        <li class="level-up-quiz-intro__what-to-expect-list-item">
                            Level Up is not meant to mimic an adaptive exam.
                        </li>
                        <li class="level-up-quiz-intro__what-to-expect-list-item">
                            Questions you answer in other quiz modes will not impact your level.
                        </li>
                    </ol>
                </div>
            </template>
            <template v-else>
                <div v-dark class="level-up-quiz-intro__choose-subject-info">
                    <template v-if="incompleteSubjects.length">
                        Choose the subject you'd like to improve in. 
                    </template>
                    <template v-else>
                        Boom! You've completed all levels. Nicely done. <img src="@/assets/levelup/clap.png" alt="">
                    </template>
                </div>
                <PocketRadio
                    :key="`${selectedSubject}-${subjectOptions.length}`"
                    v-model="selectedSubject"
                    v-dark
                    :is-dark-mode="isDarkMode"
                    :data="subjectOptions"
                    class="level-up-quiz-intro__subjects"
                >
                    <template #radioItemLabel="{ item }">
                        <div
                            class="level-up-quiz-intro__subject-label"
                            v-html="item.label"
                            v-dark
                        />
                        <div
                            v-if="selectedSubjectLevels
                                && selectedSubjectCurrentLevel
                                && (selectedSubject && selectedSubject.value) === item.value
                            "
                            class="level-up-quiz-intro__subject-levels-container"
                        >
                            <template
                                v-if="selectedSubject
                                    && Object.values(accentMessages).includes(selectedSubject.value)
                                "
                            >
                                <div
                                    v-if="selectedSubject.value == accentMessages.goodProgress"
                                    class="level-up-quiz-intro__accent-message-left"
                                >
                                    You're making good progress.
                                </div>
                                <div
                                    v-else-if="selectedSubject.value == accentMessages.soClose"
                                    class="level-up-quiz-intro__accent-message-right"
                                >
                                    Sooooo close! Keep up the good work.
                                </div>
                                <div
                                    v-else-if="selectedSubject.value == accentMessages.almostFinished"
                                    class="level-up-quiz-intro__accent-message-right"
                                >
                                    Nice! You've almost finished this subject.
                                </div>
                            </template>
                            <div class="level-up-quiz-intro__subject-levels">
                                <div
                                    v-for="(subjectLevel, index) in selectedSubjectLevels"
                                    :key="index"
                                    class="level-up-quiz-intro__subject-level"
                                >
                                    <LevelBadge
                                        v-if="selectedSubjectProgress"
                                        :is-dark-mode="isDarkMode"
                                        size="small"
                                        :status="((index + 1) < selectedSubjectCurrentLevel)
                                            || (selectedSubjectProgress.score >= selectedSubjectProgress.goal)
                                            ? 'complete'
                                            : (index + 1) > selectedSubjectCurrentLevel
                                                ? 'locked'
                                                : 'active'
                                        "
                                        :level-num="index + 1"
                                        :percent-start="(
                                            selectedSubjectProgress.score / selectedSubjectProgress.goal
                                        ) * 100"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                </PocketRadio>
                <PocketButton
                    class="level-up-quiz-intro__reset-btn"
                    type="secondary"
                    :is-dark-mode="isDarkMode"
                    @click="resetClicked"
                >
                    Reset Level Up Progress
                </PocketButton>
            </template>
        </div>
        <div v-dark class="level-up-quiz-intro__footer">
            <PocketButton
                type="secondary"
                :is-dark-mode="isDarkMode"
                @click="previousStep"
            >
                {{
                    step === 'whatToExpect' || (step === 'selectSubject' && levelUpOpenedCount >= 3)
                        ? 'Cancel'
                        : 'Back'
                }}
            </PocketButton>
            <PocketButton
                class="level-up-quiz-intro__next-btn"
                :is-dark-mode="isDarkMode"
                :disabled="hasActiveSubscription && !selectedSubject"
                :is-loading="isLoading"
                @click="nextStep"
            >
                {{
                    hasActiveSubscription
                        ? step === 'whatToExpect'
                            ? 'Select Subject'
                            : 'Start Quiz'
                        : 'Upgrade to Premium'
                }}<Icon
                    v-if="hasActiveSubscription"
                    type="arrow"
                    class="level-up-quiz-intro__next-btn-arrow"
                />
            </PocketButton>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import type { TCreateActiveQuizParams } from '@/store/quiz/actions'
import { userModule } from '@/store/user/module'
import { subscriptionModule } from '@/store/subscription/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { screenModule } from '@/store/screen/module'
import LevelBadge from '@/components/LevelBadge.vue'
import BottomRightBlob from '@/components/LevelUpQuizIntro/BottomRightBlob.vue'
import TopLeftBlob from '@/components/LevelUpQuizIntro/TopLeftBlob.vue'
import LevelUpResetModal from '@/components/LevelUpQuizIntro/LevelUpResetModal.vue'
import UpgradeSidePanel from '@/components/Settings/UpgradeSidePanel.vue'
import { quizModule } from '@/store/quiz/module'
import { questionModule } from '@/store/question/module'
import { analyticsModule } from '@/store/analytics/module'
import { bundleModule } from '@/store/bundle/module'
import { stripeModule } from '@/store/stripe/module'

@Component({
    components: {
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
        PocketButton: UIKit.Button,
        PocketRadio: UIKit.Radio,
        PocketIcon: UIKit.Icon,
        Slider: UIKit.Slider,
        Icon: UIKit.Icon,
        PocketInput: UIKit.Input,
        LevelBadge,
        BottomRightBlob,
        TopLeftBlob,
        LevelUpResetModal,
        UpgradeSidePanel,
    },
})
export default class LevelUpQuizIntro extends Vue {
    isLoading = true
    step: 'whatToExpect' | 'selectSubject' = 'whatToExpect'
    selectedSubject: LevelUpQuizIntro['subjectOptions'][number] | null = null
    showUpgradeSidePanel = false
    showResetModal = false
    accentMessages: Record<'goodProgress' | 'soClose' | 'almostFinished', string | null> = {
        goodProgress: null,     // Started level 1
        soClose: null,          // 75% of subject's levels complete
        almostFinished: null,   // Started last level
    }
    isResetLoading = false

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get hasActiveSubscription () {
        return subscriptionModule.getters.getSubscriptionForExamId()
    }

    get currentExamMetadata () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get levelUpOpenedCount () {
        return userModule.state.user?.webConfig?.levelUpOpenedCount || 0
    }

    get levelUpProgress () {
        return userExamMetadataModule.getters.getLevelUpProgress()
    }

    get subjectsWithLevels () {
        return examMetadataModule.state.subjectsWithLevels
    }

    get incompleteSubjects () {
        return this.levelUpProgress.filter(progress => {
            const subjectNumLevels = this.subjectsWithLevels
                .find(subjectWithLevels => subjectWithLevels.subjectId === progress.subjectId)?.levels?.length
            return subjectNumLevels && progress.level <= subjectNumLevels && progress.score < progress.goal
        })
    }

    get subjectOptions () {
        return this.levelUpProgress.filter(progress => {
            const subjectNumLevels = this.subjectsWithLevels
                .find(subjectWithLevels => subjectWithLevels.subjectId === progress.subjectId)?.levels?.length
            return subjectNumLevels
        }).map(progress => {
            const isComplete = !this.incompleteSubjects
                .find(incompleteSubject => progress.subjectId === incompleteSubject.subjectId)
            return {
                label: `
                    <span>${progress.subjectName}</span>
                    <span>
                        ${ isComplete ? '<span>Complete</span>' : `
                            <span>Level</span>
                            <span>${progress.level}</span>
                        `}
                    </span>
                `,
                subjectName: progress.subjectName,
                value: progress.subjectId || progress.subjectName,
                disabled: isComplete,
            }
        }).sort((a, b) => (a.subjectName).localeCompare(b.subjectName, undefined, { numeric: true }))
    }

    get selectedSubjectLevels () {
        return this.subjectsWithLevels
            .find(subjectWithLevels => subjectWithLevels.subjectId === this.selectedSubject?.value)?.levels
    }

    get selectedSubjectProgress () {
        return this.levelUpProgress.find(progress => progress.subjectId === this.selectedSubject?.value)
    }

    get selectedSubjectCurrentLevel () {
        return this.selectedSubjectProgress?.level
    }

    get maxQuizSize () {
        const currentLevelSize = this.selectedSubjectLevels?.[(this.selectedSubjectCurrentLevel || 1) - 1] || 0
        const currentLevelScore = this.selectedSubjectProgress?.score || 0
        return Math.max(currentLevelSize - currentLevelScore, 2)    // Don't go lower than 2
    }

    get serialQuestionInfoLib () {
        return questionModule.getters.getSerialQuestionInfoLib({ questionFilter: 'all' })
    }

    async mounted () {
        if (this.$route.query?.step === 'selectSubject') {
            this.step = 'selectSubject'
        }
        
        await Promise.all([
            bundleModule.actions.fetchBundles(),
            examMetadataModule.actions.fetchExamMetadata(),
            userExamMetadataModule.actions.fetchUserExamMetadata(),
            userModule.actions.fetchUserData(),
            stripeModule.actions.fetchStripePlans(),
            stripeModule.actions.fetchPaymentMethods(),
        ])
        await questionModule.actions.fetchSerialQuestionInfoLib()

        if (this.levelUpOpenedCount >= 3) {
            this.step = 'selectSubject'
        }
        await this.updateSubjectOptions()
        this.isLoading = false

        if (this.incompleteSubjects.length === 0) {
            await analyticsModule.actions.sprigTrack({
                eventName: 'Level_Up_All_Subjects_Complete',
            })
        }
    }

    previousStep () {
        if (this.step === 'whatToExpect' || (this.step === 'selectSubject' && this.levelUpOpenedCount >= 3)) {
            this.backToStudy()
        } else {
            this.step = 'whatToExpect'
        }
    }

    nextStep () {
        if (!this.hasActiveSubscription) {
            this.showUpgradeSidePanel = true
        } else {
            if (this.step === 'whatToExpect') {
                this.step = 'selectSubject'
            } else {
                this.startQuiz()
            }
        }
    }

    async backToStudy () {        
        this.$router.push({
            name: 'study',
        })
    }

    async startQuiz () {        
        if (this.selectedSubject && !this.isLoading) {
            this.isLoading = true
            const goal = this.selectedSubjectProgress?.goal || 0
            const score = this.selectedSubjectProgress?.score || 0
            const numCorrectToLevelUp = goal - score
            const activeQuizParams: TCreateActiveQuizParams = {
                mode: 'levelUp',
                questionCount: 10,
                level: this.selectedSubjectCurrentLevel,
                subjectIds: [ this.selectedSubject.value ],
                numCorrectToLevelUp,
            }

            await quizModule.actions.createActiveQuiz(activeQuizParams)
            this.$router.push({ name: 'quiz' })
        }

        this.isLoading = false
    }

    resetClicked () {
        this.showResetModal = true
    }

    async resetProgress () {
        this.isResetLoading = true
        await userExamMetadataModule.actions.resetLevelUpProgress()

        this.accentMessages = {
            almostFinished: null,
            soClose: null,
            goodProgress: null,
        }
        this.subjectOptionsChanged(this.subjectOptions)
        this.showResetModal = false
        this.isResetLoading = false
    }

    async updateSubjectOptions () {
        const currentUEM = userExamMetadataModule.getters.getCurrentUserExamMetadata()
        if (!currentUEM) {
            return
        }
        const examGuid = this.currentExamMetadata?.examGuid
        const examMetadataId = this.currentExamMetadata?.objectId
        const examVersion = this.currentExamMetadata?.version

        if (examGuid && examMetadataId && examVersion) {
            // Check for outdated subjects in levelUpProgress
            if (this.levelUpProgress.length > this.subjectsWithLevels.length) {
                const validSubjectIdSet = new Set(this.subjectsWithLevels.map(s => s.subjectId))
                const fixedLevelUpProgress = this.levelUpProgress.filter((progress, index, levelUpProgress) =>
                    progress.subjectId && validSubjectIdSet.has(progress.subjectId)
                    && levelUpProgress.findIndex(p => p.subjectId === progress.subjectId) === index // remove duplicates
                )
                if (currentUEM?.objectId && fixedLevelUpProgress.length === validSubjectIdSet.size) {
                    await userExamMetadataModule.actions.upsertUserExamMetadata({
                        objectId: currentUEM.objectId,
                        levelUpProgress: fixedLevelUpProgress,
                    })
                }
            }
        }

        // Start the user's Level Up progress for any unstarted subjects
        const unstartedSubjectIds = this.subjectsWithLevels.filter(subject => {
            return !this.levelUpProgress.find(progress => progress.subjectId === subject.subjectId)
        }).map(subject => subject.subjectId)

        if (unstartedSubjectIds.length) {
            await userExamMetadataModule.actions.startNewLevels({
                subjectIds: unstartedSubjectIds,
            })
        }

        const recentLevelUpSubjectIds = quizModule.getters.getQuizzes()
            .filter(quiz => quiz.mode === 6)
            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
            .map(quiz => {
                const quizSubjectId = quiz.levelUpProgress?.subjectId
                const isSubjectIncomplete = !!this.incompleteSubjects
                    .find(incompleteSubject => incompleteSubject.subjectId === quizSubjectId)
                return isSubjectIncomplete ? quizSubjectId : null
            }).filter(incompleteSubject => !!incompleteSubject)

        if (recentLevelUpSubjectIds.length) {
            const mostRecentSubject = recentLevelUpSubjectIds[0]
            this.selectedSubject = this.subjectOptions
                .find(subjectOption => subjectOption.value === mostRecentSubject) || null
        }
        
        if (!this.selectedSubject) {
            const firstIncompleteSubjectOption = this.subjectOptions
                .find(subjectOption => this.incompleteSubjects
                    .find(incompleteSubject => incompleteSubject.subjectId === subjectOption.value)
                )
            this.selectedSubject = firstIncompleteSubjectOption || null
        }
    }

    @Watch('subjectOptions', { deep: true })
    subjectOptionsChanged (subjectOptions: LevelUpQuizIntro['subjectOptions']) {
        // Check if any subjects should have an accent message
        subjectOptions.forEach(subjectOption => {
            const subjectId = subjectOption.value
            const subjectProgress = this.levelUpProgress.find(progress => progress.subjectId === subjectId)
            const subjectNumLevels = this.subjectsWithLevels
                .find(subjectWithLevels => subjectWithLevels.subjectId === subjectId)?.levels?.length
            // Check for goodProgress accent message
            if (
                subjectProgress?.level === 1
                && subjectProgress.score > 0
                && this.accentMessages.goodProgress === null
            ) {
                this.accentMessages.goodProgress = subjectId
            }
            // Check for almostFinished accent message
            if (
                subjectNumLevels
                && subjectNumLevels > 1
                && subjectProgress?.level === subjectNumLevels
                && this.accentMessages.almostFinished === null
                && subjectProgress.score < subjectProgress.goal
            ) {
                this.accentMessages.almostFinished = subjectId
            } else if (
                subjectNumLevels
                && subjectNumLevels >= 4
                && subjectProgress?.level
                && subjectProgress.level >= (subjectNumLevels * 0.75)
            ) {
                this.accentMessages.soClose = subjectId
            }
        })
    }

    @Watch('currentExamMetadata')
    async currentExamChanged () {
        // wait for any active UEM fetching to resolve before managing level up progress
        await userModule.actions.fetchUserData()
        await userExamMetadataModule.actions.fetchUserExamMetadata()
        await this.updateSubjectOptions()
    }
}
</script>

<style lang="scss" scoped>
.level-up-quiz-intro {
    height: 100%;
    padding: 100px 10px 32px;
    box-sizing: border-box;
    background-color: $gray-background;
    overflow: auto;

    @include breakpoint(black-bear) {
        padding-top: 80px;
    }

    &__close {
        position: absolute;
        right: 29px;
        top: 29px;
        color: $brand-blue;
        width: 30px;
        height: 30px;
        cursor: pointer;

        @include breakpoint(black-bear) {
            top: 16px;
            right: 16px;
        }

        &:hover {
            color: $brand-black;
        }

        &--dark {
            color: $banana-bread;

            &:hover {
                color: $butterscotch;
            }
        }
    }

    &--dark {
        background-color: $charcoal;
    }

    &__bottom-right-blob {
        position: absolute;
        bottom: 87px;
        right: 0;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__top-left-blob {
        position: absolute;
        top: 0;
        left: 0;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__content {
        margin: 0 auto;
        max-width: 520px;

        @include breakpoint(black-bear) {
            max-width: 343px;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        position: relative;
        font-size: 26px;
        line-height: 34px;
        font-weight: 600;
        padding-left: 40px;
        margin-right: 8px;
        margin-bottom: 32px;
        color: $ash;

        &--dark {
            color: $fog;
        }

        @include breakpoint(black-bear) {
            padding-left: 8px;
        }
    }

    &__title-icon {
        position: absolute;
        left: 5px;
        top: 4px;

        @include breakpoint(black-bear) {
            left: 8px;
            top: -30px;
        }
    }

    &__what-to-expect {
        background-color: $white;
        padding: 40px;
        border-radius: 5px;
        border: 1px solid $fog;
        margin-bottom: 84px;

        @include breakpoint(black-bear) {
            padding: 16px 17px 24px 16px;
        }

        &--dark {
            background-color: $brand-black;
            border-color: $brand-black;
            color: $white;
        }
    }

    &__before-started {
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        margin-bottom: 16px;
    }

    &__before-started-text {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 32px;
    }

    &__what-to-expect-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        margin-bottom: 16px;
    }

    &__what-to-expect-list {
        padding-left: 20px;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        margin: 0;
    }

    &__what-to-expect-list-item {
        line-height: 22px;

        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }

    &__choose-subject-info {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 16px;
        padding-left: 4px;

        &--dark {
            color: $white;
        }

        @include breakpoint(black-bear) {
            padding-left: 8px;
        }

        img {
            height: 19px;
            position: relative;
            top: 2px;
        }
    }

    &__subjects {
        margin: 0 auto;
        margin-bottom: 16px;
        border-radius: 6px;
        border: 0.5px solid rgba($pewter, 0.85);
        background-color: $white;

        &--dark {
            background-color: $brand-black;
        }
    }

    :deep(.uikit-radio__item) {
        padding: 12px 14px;
        border-radius: 0;
        border-bottom: 0.5px solid rgba($pewter, 0.85);
        cursor: pointer;

        &:first-child {
            border-radius: 6px 6px 0 0;

            &::before {
                border-radius: 6px 6px 0 0;
            }
        }

        &:last-child {
            border-bottom: 0;
            border-radius: 0 0 6px 6px;

            &::before {
                border-radius: 0 0 6px 6px;
            }
        }

        &::before {
            left: 0;
            right: 0;
            border-radius: 0;
            width: 100%;
        }
    }

    :deep(.uikit-radio__item:not(.uikit-radio__item--dark)::before) {
        background: $pearl;
    }

    :deep(.uikit-radio__item--selected) {
        cursor: auto;
    }

    :deep(
        .uikit-radio__item:not(.uikit-radio__item--selected):not(.uikit-radio__item--disabled)
        .level-up-quiz-intro__subject-label--dark
    ) {
        color: $fog;
    }

    :deep(.uikit-radio__item--selected:not(.uikit-radio__item--selected--dark)::before) {
        background: $white;
    }

    :deep(.uikit-radio__item--selected--dark::before) {
        background: $brand-black;
    }

    :deep(.uikit-radio__item--disabled) {
        cursor: auto;
    }

    :deep(.uikit-radio__item--disabled:not(.uikit-radio__item--disabled--dark)) {
        background: $barely-background;
        color: $slate;
    }

    :deep(.uikit-radio__item::after) {
        width: 100%;
        left: 0;
        right: 0;
    }

    :deep(.uikit-radio-btn) {
        min-width: 18px;
        margin-right: 12px;
    }

    :deep(.uikit-radio__item-label) {
        display: flex;
        justify-content: space-between;

        & > span {
            &:first-child {
                padding-right: 12px;
            }

            &:last-child {
                min-width: 85px;
            }
        }
    }

    &__subject-label {
        display: flex;
        justify-content: space-between;
        max-width: 460px;

        @include breakpoint(black-bear) {
            max-width: 280px;
        }

        & > :deep(span:first-child) {
            max-width: 376px;

            @include breakpoint(black-bear) {
                max-width: 186px;
            }
        }
    }

    &__subject-levels-container {
        display: flex;
        position: relative;
        left: -44px;
        margin: 12px 0;
        width: 520px;
        padding-left: 11px;

        @include breakpoint(black-bear) {
            width: 342px;
        }
    }

    &__accent-message-left {
        position: absolute;
        top: 12px;
        left: -178px;
        width: 92px;
        font-size: 13px;
        line-height: 18px;
        
        &::before {
            content: '';
            position: absolute;
            right: -70px;
            top: -7px;
            background: url(@/assets/onboarding/tip-arrow.svg) no-repeat center center;
            width: 65px;
            height: 14px;
            transform: rotate(10deg) scaleX(-1);
        }

        @include breakpoint(brown-bear) {
            display: none;
        }
    }

    &__accent-message-right {
        position: absolute;
        top: 12px;
        right: -208px;
        width: 122px;
        font-size: 13px;
        line-height: 18px;

        &::before {
            content: '';
            position: absolute;
            left: -72px;
            top: -7px;
            background: url(@/assets/onboarding/tip-arrow.svg) no-repeat center center;
            width: 65px;
            height: 14px;
            transform: rotate(-10deg);

            @include breakpoint(black-bear) {
                transform: rotate(-50deg);
                top: 18px;
            }
        }

        @include breakpoint(brown-bear) {
            display: none;
        }
    }

    &__subject-levels {
        display: flex;
        flex-wrap: wrap;
        max-width: 490px;

        @include breakpoint(black-bear) {
            max-width: 294px;
        }
    }

    &__subject-level {
        margin: 4px;
    }

    &__reset-btn {
        margin-bottom: 104px;
    }

    &__footer {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 87px;
        background-color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid $fog;
        z-index: 2;

        &--dark {
            background-color: $mariner;
            border-color: $brand-black;
        }

        @include breakpoint(black-bear) {
            height: 68px;
        }
    }

    &__next-btn {
        margin-left: 8px;
        padding-left: 14px;
        padding-right: 12px;
    }

    &__next-btn-arrow {
        margin-left: 10px;
    }
}
</style>
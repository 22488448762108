<template>
    <div
        v-dark
        class="mode"
        :class="{ 'mode--qotd-answered': isQotdAnswered }"
        tabindex="0"
        role="button"
        :aria-label="`Start ${name} ${ modeNumber !== '5' ? 'quiz' : ''}${ isBeta ? ', Beta' : '' }`"
        :aria-disabled="name === 'Question of the Day' && isQotdAnswered ? 'true' : 'false'"
        @click="(name !== 'Question of the Day' || !isQotdAnswered) && clickMode()"
        @keydown.enter="(name !== 'Question of the Day' || !isQotdAnswered) && clickMode()"
        @mousedown.prevent
    >
        <Tag
            v-if="isBeta"
            class="mode__beta"
            :class="{
                'mode__beta--premium': isPremium && !hasActiveSubscription,
            }"
            :size="'small'"
            :is-beta-tag="true"
            :is-dark-mode="isDarkMode"
        >
            <template #tagMessage>
                Beta
            </template>
        </Tag>
        <Tag
            v-if="!isLoading && isPremium && !hasActiveSubscription"
            class="mode__premium"
            :size="'small'"
            :is-dark-mode="isDarkMode"
        >
            <template #tagMessage>
                Premium
            </template>
        </Tag>
        <div 
            class="mode__icon"
            :class="{ 
                [`mode__icon--${modeIcon}`]: true, 
                [`mode__icon--${modeIcon}--dark`]: isDarkMode,
                'mode__icon--qotd-answered': isQotdAnswered,
                'mode__icon--qotd-answered--dark': isQotdAnswered && isDarkMode,
            }"
            :style="{ color: modeColor }"
        >
            <Icon :type="modeIcon" />
        </div>
        <div
            v-dark
            class="mode__name"
            :class="{ 'mode__name--qotd-answered': isQotdAnswered }"
        >
            {{ name }}
        </div>
        <div
            v-if="name === 'Question of the Day'"
            v-dark
            :class="{ 'mode__qotd--qotd-answered': isQotdAnswered }"
            class="mode__qotd"
        >
            {{ todaysDate }}
        </div>
    </div>
</template>

<script lang="ts">
import { subscriptionModule } from '@/store/subscription/module'
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'
import UIKit, { utils } from '@pocketprep/ui-kit'
import { userModule } from '@/store/user/module'
import { qotdModule } from '@/store/qotd/module'
import { months } from '@/utils'

@Component({
    components: {
        Icon: UIKit.Icon,
        Tag: UIKit.Tag,
    },
})
export default class Mode extends Vue {
    @Prop({ default: false }) isPremium!: boolean
    @Prop({ default: false }) isBeta!: boolean
    @Prop() name!: string

    isLoading = true

    get todaysDate () {
        const month = new Date().getMonth()

        return `${months[month]?.substr(0, 3)} ${new Date().getDate()}`
    }

    get hasActiveSubscription () {
        return subscriptionModule.getters.getSubscriptionForExamId()
    }

    get isQotdAnswered () {
        return (!!qotdModule.getters.getQotDQuiz() || this.isLoading) && this.name === 'Question of the Day'
    }

    get qotdQuiz () {
        return qotdModule.getters.getQotDQuiz()
    }

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get modeNumber () {
        let modeNumber: keyof typeof utils.studyModes = '10'
        if (this.name === 'Quick 10') {
            modeNumber = '0'
        } else if (this.name === 'Timed Quiz') {
            modeNumber = '4'
        } else if (this.name === 'Question of the Day') {
            modeNumber = '-1'
        } else if (this.name === 'Weakest Subject') {
            modeNumber = '3'
        } else if (this.name === 'Missed Questions') {
            modeNumber = '2'
        } else if (this.name === 'Mock Exam') {
            modeNumber = '5'
        } else if (this.name === 'Level Up') {
            modeNumber = '6'
        }

        return modeNumber
    }

    get modeColor () {
        return this.isDarkMode
            ? utils.studyModes[this.modeNumber].iconColorDM
            : utils.studyModes[this.modeNumber].iconColor
    }

    get modeIcon () {
        return utils.studyModes[this.modeNumber].icon
    }

    async mounted () {
        await Promise.all([
            userModule.actions.fetchUserData(),
            qotdModule.actions.fetchCurrentQotDQuestion(),
        ])
        this.isLoading = false
    }

    clickMode () {
        this.emitClick(this.name)
    }

    @Emit('click')
    emitClick (modeName: string) {
        return modeName
    }
}
</script>
<style lang="scss" scoped>
.mode {
    width: 158px;
    height: 106px;
    border-radius: 6px;
    border: 1px solid rgba($pewter, 0.85);
    box-shadow: 0 1px 4px 0 rgba($ash, 0.3);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    display: flex;
    user-select: none;
    position: relative;
    flex-direction: column;
    outline: none;
    background: $white;

    @include breakpoint(brown-bear) {
        width: 222px;
        height: 114px;
    }

    @include breakpoint(black-bear) {
        width: 100%;
        height: 50px;
        flex-direction: row;
        justify-content: flex-start;
    }

    &:not(&--qotd-answered):focus {
        border: 1px solid $brand-blue;
    }

    &:not(&--qotd-answered):hover {
        border: 2px solid $slate-01;
        cursor: pointer;

        .mode__premium {
            top: 2px;
            left: 2px;

            @include breakpoint(black-bear) {
                top: 50%;
                right: 11px;
                left: auto;
            }
        }

        .mode__beta {
            top: 2px;
            right: 2px;
            @include breakpoint(black-bear) {
                top: 50%;
                right: 11px;
                left: auto;
            }
            &--premium {
                @include breakpoint(black-bear) {
                    top: 50%;
                    right: 74px;
                    left: auto;
                }
            }
        }

        .mode__icon {
            @include breakpoint(black-bear) {
                margin-left: 11px;
            }
        }

        .mode__qotd {
            top: 17px;
            right: 13px;
        }
    }

    &--dark {
        background: $brand-black;
        border-color: $slate;
        box-shadow: 0 1px 4px 0 rgba($jet, 0.3);
        color: $white;

        &:not(&.mode--qotd-answered):hover {
            border-color: $brand-black;

            &::before {
                content: '';
                left: -2px;
                top: -2px;
                position: absolute;
                width: 100%;
                height: 100%;
                border: 2px solid $white;
                opacity: 0.6;
                border-radius: 6px;
            }
        }

        &:not(&.mode--qotd-answered):focus {
            border-color: $brand-black;

            &::before {
                content: '';
                left: -2px;
                top: -2px;
                position: absolute;
                width: calc(100% + 2px);
                height: calc(100% + 2px);
                border: 1px solid $banana-bread;
                border-radius: 6px;
            }
        }
    }

    &__icon--subject {
        svg {
            height: 26px;
            width: 20px;
        }
    }

    &__premium,
    &__beta {
        position: absolute;
        top: 3px;
        left: 3px;

        @include breakpoint(black-bear) {
            top: 50%;
            margin-top: -9px;
            right: 12px;
            left: auto;
        }
    }

    &__beta {
        left: auto;
        right: 3px;
        @include breakpoint(black-bear) {
            right: 12px;
        }
        &--premium {
            @include breakpoint(black-bear) {
                right: 75px;
            }
        }
    }

    &__icon {
        margin-bottom: 8px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint(brown-bear) {
            margin-bottom: 18px;
        }

        @include breakpoint(black-bear) {
            margin: 0 12px;
        }

        &--qotd-answered {
            color: $steel !important;

            &--dark {
                color: $slate !important;
            }
        }
    }

    &__name {
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;

        &--qotd-answered {
            color: $slate;

            &--dark {
                color: $steel;
            }
        }
    }

    &__qotd {
        color: $brand-blue;
        position: absolute;
        right: 14px;
        top: 18px;
        display: none;
        font-size: 14px;

        @include breakpoint(black-bear) {
            display: block;
        }

        &::after {
            content: '';
            width: 7px;
            height: 7px;
            background: $brand-blue;
            border-radius: 7px;
            margin-left: 5px;
            display: inline-block;
            margin-bottom: 1px;
        }

        &--dark {
            color: $banana-bread;

            &::after {
                background: $banana-bread;
            }
        }

        &--qotd-answered {
            color: $slate;

            &::after {
                display: none;
            }

            &--dark {
                color: $steel;
            }
        }
    }
}
</style>